import React, { useEffect, useState } from "react";
import { DocumentTemplate, Variable } from "../../../types/DocumentTemplate";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { Alert, Box, DialogActions } from "@mui/material";
import * as XLSX from "xlsx";
import BulkIcon from "../../Icons/Document Bulk Generation/Variable Document.svg";
import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  generateDocumentExcelTemplate,
  hasMatchingVariables,
  validateData
} from "./BulkUtils";
import VariableTable from "../variableTables/VariableTable";
import { FileUploadProps } from "../../shared/FileUpload";
import { ExcelFileUpload } from "../../shared/ExcelFileUpload";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { selectAuth } from "../../auth/authSlice";
import WarningIcon from "@mui/icons-material/Warning";
import {
  selectLibrary,
  setCreditCount,
  updateCreditCount
} from "../../library/librarySlice";

export default function BulkDocumentCreationForm(props: {
  parentCloseHandle: () => void;
  documentTemplate: DocumentTemplate;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const selectedOrg = authState.organizations?.find(
    (org) => org.id === authState.user?.default_organization_id
  );
  const libraryState = useAppSelector(selectLibrary);
  const [isBulkConfirmation, setIsBulkConfirmation] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [documentvariables, setVariables] = useState<Variable[]>([]);
  const [payload, setPayload] = useState<any | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");

  function copyVariables() {
    if (!props.documentTemplate.variables) return [] as Variable[] | [];
    const newVariables = [] as Variable[];
    for (const v of props.documentTemplate.variables) {
      if (!v.fillable_field) {
        newVariables.push({ ...v });
      }
    }
    return newVariables;
  }

  React.useEffect(() => {
    setVariables(copyVariables());
  }, [props.documentTemplate.variables]);

  const onClose = () => {
    props.parentCloseHandle();
  };

  const handleFileUploadInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      if (
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel"
      ) {
        setUploadedFile(selectedFile);
        setInvalidFileType(false);
        handleFileUpload(selectedFile);
      } else {
        setInvalidFileType(true);
        setUploadedFile(null);
        setErrorMsg("");
      }
    }
  };
  const handleCreateBulkRecords = () => {
    if (payload != null) {
      actions.createBulkDocumentRecord({
        documentTemplate: props.documentTemplate,
        bulkDocuments: payload
      });
      dispatch(updateCreditCount(libraryState.creditCount! - payload.length));
    }
    setIsBulkConfirmation(false);

    props.parentCloseHandle();
  };

  const handleFileUpload = (uploadedFile: any) => {
    setErrorMsg("");
    setPayload(null);

    const file = uploadedFile;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
          const worksheet = workbook.Sheets[sheetName];
          const filteredData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "" // Set the default value for empty cells to an empty string
          });

          const dataabc = filteredData.filter((row) =>
            row.some((cell) => cell !== "")
          );
          const parsedData = dataabc
            .filter((row) => row.some((cell) => cell !== ""))
            .map((row) => row.map((cell) => String(cell)));
          // Assuming the variable names are in the first row of the file, and the document names are merged in the first column.
          const variables = parsedData[0].slice(1); // Skip the first cell (document name)

          const variableValidation = hasMatchingVariables(
            variables,
            documentvariables
          );
          if (variableValidation?.invalid) {
            if (variableValidation.dupliacte) {
              setErrorMsg(
                `Dupliacte Variable: ${variableValidation.invalidVariable}`
              );
            } else {
              setErrorMsg(
                `Missing Variable: ${variableValidation.invalidVariable}`
              );
            }
            return;
          }

          const rows = parsedData.slice(1); // Skip the first row (variable names)

          const objectsCheckValue = rows
            .map((row) => {
              const [documentName, ...variableValues] = row;

              const rowObjects = variables.map((variableName, index) => ({
                documentName,
                variableName,
                variableValue: variableValues[index] || "undefined"
              }));

              return rowObjects;
            })
            .flat()
            .filter(
              (obj) =>
                obj.documentName !==
                  "Copy above format if more documents needed…" &&
                (obj.documentName || obj.variableName || obj.variableValue)
            );

          const isAnyUndefinedVariableValue = objectsCheckValue.some(
            (item) =>
              item.variableValue === "undefined" ||
              typeof item.variableValue === "undefined"
          );

          if (isAnyUndefinedVariableValue) {
            setErrorMsg(`Variable value cannot be empty`);
            return false;
          }

          const objects = rows
            .filter(
              (row) =>
                row[0] !== "Copy above format if more documents needed…" &&
                (row[0] || row.slice(1).some((cell) => cell))
            )
            .flatMap((row) => {
              const [documentName, ...variableValues] = row;
              return variableValues.map((variableValue, index) => ({
                documentName,
                variableName: variables[index],

                variableValue: variableValue || "undefined" // Set to empty string if falsy
              }));
            });

          const isAnyUndefined = objects.some(
            (item) => typeof item.documentName === "undefined"
          );

          if (isAnyUndefined) {
            setErrorMsg(`Document name cannot be empty`);
            return false;
          }

          // Create payloads for each group

          if (validateData(objects, documentvariables).isValid) {
            const groupedData = objects.reduce((result: any, obj: any) => {
              const { documentName } = obj;
              if (!result[documentName]) {
                result[documentName] = [];
              }
              result[documentName].push(obj);
              return result;
            }, {});

            const payloads = Object.entries(groupedData).map(
              ([documentName, group]) => ({
                name: documentName,
                variable_values: JSON.stringify(
                  (group as any[]).map((obj: any) => ({
                    variable_name:
                      documentvariables.length != 0 ? obj.variableName : "",
                    value:
                      documentvariables.length != 0
                        ? obj.variableValue !== "null" &&
                          obj.variableValue !== "Null" &&
                          obj.variableValue !== "NULL"
                          ? obj.variableValue
                          : ""
                        : ""
                  }))
                )
              })
            );
            setPayload(payloads);
            setErrorMsg("");
          } else {
            setPayload(null);
            const validationResult = validateData(
              objects,
              documentvariables ? documentvariables : []
            );

            const errorMessages = [];

            // Check for invalid document names
            if (
              validationResult.invalidDocumentNames &&
              validationResult.invalidDocumentNames.length > 0
            ) {
              errorMessages.push(
                "Errors have occurred in the document name field"
              );
              validationResult.invalidDocumentNames.forEach(
                (invalidDocName) => {
                  errorMessages.push(
                    `Duplicate Document Name: ${invalidDocName[0]}`
                  );
                  errorMessages.push("");
                }
              );
            }

            // Check for invalid variable names
            if (
              validationResult.invalidVariableNames &&
              validationResult.invalidVariableNames.length > 0
            ) {
              errorMessages.push(
                `Errors have occurred in the variable field ${validationResult.invalidVariableNames} `
              );
            }

            if (
              validationResult.invalidVariableValues &&
              validationResult.invalidVariableValues.length > 0
            ) {
              errorMessages.push("Variable value cannot start with '@'");
            }

            setErrorMsg(errorMessages.join(", "));
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const fileUploadProp: FileUploadProps = {
    accept: ".xlsx", // Accept only Excel files
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        const droppedFile = event.target.files[0];
        if (droppedFile && droppedFile.name.endsWith(".xlsx")) {
          setUploadedFile(droppedFile);
          setInvalidFileType(false);
          handleFileUpload(droppedFile);
        } else {
          setInvalidFileType(true);
          setUploadedFile(null);
          setErrorMsg("");
        }
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile && droppedFile.name.endsWith(".xlsx")) {
        setUploadedFile(droppedFile);
        setInvalidFileType(false);
      } else {
        setUploadedFile(null);
        setErrorMsg("Please upload a valid Excel file (XLSX or XLS format).");
      }
    }
  };

  const InstructionsAccordion = (
    <>
      <Box boxShadow={3} sx={{ marginTop: 2 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Instructions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <strong>Downloading the Excel Template</strong>
              <br />
              1. Click "Download" to get the Excel template.
              <br />
              2. Save the file.
              <br />
              <br />
              <strong>Filling the Excel Template</strong>
              <br />
              1. Duplicate the format for multiple documents.
              <br />
              2. Must provide unique document name.
              <br />
              3. Do not change the variable name.
              <br />
              4. Fill up the values in variable column.
              <br />
              <br />
              <strong>Uploading the Filled Excel File</strong>
              <br />
              1. Save the filled file.
              <br />
              2. Upload it to create Bulk templates.
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle
          style={{
            fontSize: "1.5rem",

            textAlign: "left",
            padding: "10px",
            paddingLeft: "23px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <AlternateEmailIcon
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "30px",
              color: "primary.main"
            }}
          />

          <Typography id=" Bulk Document Creation__" variant="h5">
            Bulk Document Creation
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          {documentvariables.length > 0
            ? VariableTable(documentvariables, true)
            : null}

          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <Button
              onClick={() => {
                generateDocumentExcelTemplate(
                  documentvariables ? documentvariables : [],
                  props.documentTemplate.name
                );
              }}
              variant="contained"
              style={{
                backgroundColor: "primary.main",
                boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
                textTransform: "uppercase",
                fontSize: "1rem",
                padding: "10px 20px",
                fontWeight: "bold",
                letterSpacing: "0.5px",
                transition: "background-color 0.2s ease-in-out"
              }}
            >
              Download Template
              {/* Inprogress... */}
            </Button>

            {InstructionsAccordion}
          </div>

          <ExcelFileUpload {...fileUploadProp} />
          {uploadedFile !== null && (
            <Typography
              sx={{
                p: 2,
                fontWeight: "bold",
                color: errorMsg == "" ? "green" : "red"
              }}
            >
              {" "}
              File Name: {uploadedFile.name}
            </Typography>
          )}

          {invalidFileType ? (
            <Alert severity="error">
              Please upload a valid Excel file (XLSX or XLS format).
            </Alert>
          ) : null}
          <div
            style={{
              maxHeight: errorMsg ? "100px" : "0",
              overflow: "hidden",
              transition: "max-height 0.3s ease-in-out"
            }}
          >
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          </div>

          {/* <div style={{ marginTop: "20px", textAlign: "center" }}>
            <label
              htmlFor="fileInput"
              style={{
                fontSize: "1rem",
                display: "block",
                marginBottom: "5px",
              }}
            >
              Choose an Excel File:
            </label>
            <input
              type="file"
              id="fileInput"
              accept=".xlsx, .xls"
              onChange={handleFileUploadInput}
              style={{
                border: "1px solid #ccc",
                borderRadius: "6px",
                padding: "8px 12px",
                fontSize: "1rem",
                boxShadow: "none",
                outline: "none",
                background: "#f7f7f7",
                transition: "border-color 0.3s ease",
                width: "100%",
              }}
            />

            {invalidFileType ? (
              <Alert severity="error">
                Please upload a valid Excel file (XLSX or XLS format).
              </Alert>
            ) : null}
            {errorMsg != "" ? <Alert severity="error">{errorMsg}</Alert> : null}
          </div> */}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              errorMsg != "" || payload == null || libraryState.creditCount == 0
            }
            style={{
              color: "#fff",
              boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
              textTransform: "uppercase",
              transition: "background-color 0.2s ease-in-out"
            }}
            onClick={() => {
              setIsBulkConfirmation(true);
            }}
          >
            Bulk create documents
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isBulkConfirmation}
        onClose={() => setIsBulkConfirmation(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{ textAlign: "center", fontWeight: "bold", color: "#f44336" }}
        >
          <WarningIcon
            style={{
              marginRight: "8px",
              fontSize: "24px",
              verticalAlign: "middle"
            }}
          />
          Warning: Bulk Document generation
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "24px"
            }}
          >
            {/* { payload && payload.length &&
 <span>
 Do you really want to generate {payload.length} document(s)?
 Your new remaining credit will be{" "}
 <span style={{ fontWeight: "bold", color: "green" }}>
 {libraryState.creditCount !== undefined ? libraryState.creditCount - payload.length : 0}
 </span>
 .
</span>
            } */}

            {payload && libraryState.creditCount && (
              <div>
                {libraryState.creditCount - payload.length >= 0 ||
                (selectedOrg && selectedOrg?.allow_negative_credits) ? (
                  <span>
                    Do you really want to generate {payload.length} document(s)?
                    Your new remaining credit will be{" "}
                    <span style={{ fontWeight: "bold", color: "green" }}>
                      {libraryState.creditCount - payload.length}
                    </span>
                    .
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "red",
                      fontStyle: "italic"
                    }}
                  >
                    Error: Insufficient credit.
                  </span>
                )}
              </div>
            )}
          </p>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "16px" }}
        >
          <Button
            onClick={() => setIsBulkConfirmation(false)}
            variant="outlined"
            style={{
              marginRight: "10px",
              color: "#f44336",
              borderColor: "#f44336",
              width: "80px"
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={handleCreateBulkRecords}
            style={{
              marginRight: "10px",

              borderColor: !(
                libraryState.creditCount &&
                payload &&
                libraryState.creditCount - payload.length >= 0
              )
                ? "grey"
                : "green",
              width: "80px"
            }}
            variant="outlined"
            disabled={
              !(
                libraryState.creditCount &&
                payload &&
                (libraryState.creditCount - payload.length >= 0 ||
                  (selectedOrg && selectedOrg?.allow_negative_credits))
              )
            }
          >
            create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
