interface ApiErrorState {
  currentError: string | null;
}

const initialErrorState: ApiErrorState = {
  currentError: null,
};

// Action Types
const SET_CURRENT_ERROR = "SET_CURRENT_ERROR";

// Action Creators
export const setCurrentError = (error: string | null) => ({
  type: SET_CURRENT_ERROR,
  payload: error,
});

// Reducer
const apiErrorReducer = (
  state = initialErrorState,
  action: any
): ApiErrorState => {
  switch (action.type) {
    case SET_CURRENT_ERROR:
      return { ...state, currentError: action.payload };
    default:
      return state;
  }
};

export default apiErrorReducer;
