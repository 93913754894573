export interface PaymentPlan {
  id: number;
  price: number;
  credits: number;
  planId: string | undefined;
}

export const getActionText = (subscribedPlan: any, paymentPlan: any) => {
  console.log("subscribedPlan", subscribedPlan, "paymentPlan", paymentPlan);
  if (subscribedPlan == undefined || subscribedPlan == null) {
    return {
      text: "subscribe",
      color: " #4C33FF", // Blue color
      action: "subscribe",
      showDialog: false,
      title: "",
      content: ""
    };
  }

  if (subscribedPlan === paymentPlan) {
    return {
      text: "unsubscribe",
      color: "#ff0000", // Red color
      action: "unsubscribe",
      showDialog: false,
      title: "Cancel Plan",
      content:
        "Your subscription will not be renewed. You will have access to the service for the remainder of the cycle, until the next billing cycle.Are you sure you want to cancel your current subscription plan?"
    };
  }

  // Ensuring both subscribedPlan and paymentPlan are not null before comparison
  if (
    subscribedPlan !== null &&
    paymentPlan !== null &&
    subscribedPlan < (paymentPlan ?? 0)
  ) {
    return {
      text: "upgrade",
      color: "#548235", // Green color
      action: "upgrade",
      showDialog: false,
      title: "Upgrade Plan",
      content:
        "Are you sure you want to upgrade your current subscription plan? Please cancel your current subscription first, then continue to resubscribe to another plan."
    };
  }

  return {
    text: "downgrade",
    color: "#00C482", // No specific color code provided, you can add one here if needed
    action: "downgrade",
    showDialog: false,
    title: "Downgrade Plan",
    content:
      "Are you sure you want to downgrade your current subscription plan?"
  };
};

export const monthlyPlansTrail: PaymentPlan[] = [
  {
    id: 1,
    price: 400,
    credits: 20,
    planId: "PLN_qejwt9k4bwfwp4c"
  },
  {
    id: 2,
    price: 750,
    credits: 50,
    planId: "PLN_80ilpm7gt5hu3ai"
  },
  {
    id: 3,
    price: 1200,
    credits: 100,
    planId: "PLN_9posr2js2ucqk7n"
  },
  {
    id: 4,
    price: 5000,
    credits: 500,
    planId: "PLN_5kdhkpk03f8uq56"
  },
  {
    id: 5,
    price: 8000,
    credits: 1000,
    planId: "PLN_sy3gzqiqcw7ws6p"
  }
];

export const monthlyPaymentPlans: PaymentPlan[] = [
  {
    id: 1,
    price: 400,
    credits: 20,
    planId: process.env.REACT_APP_MONTHLY_PLAN_20
  },
  {
    id: 2,
    price: 750,
    credits: 50,
    planId: process.env.REACT_APP_MONTHLY_PLAN_50
  },
  {
    id: 3,
    price: 1200,
    credits: 100,
    planId: process.env.REACT_APP_MONTHLY_PLAN_100
  },
  {
    id: 4,
    price: 5000,
    credits: 500,
    planId: process.env.REACT_APP_MONTHLY_PLAN_500
  },
  {
    id: 5,
    price: 8000,
    credits: 1000,
    planId: process.env.REACT_APP_MONTHLY_PLAN_1000
  }
];

export const yearlyPlansTrails: PaymentPlan[] = [
  {
    id: 10,
    price: 4000,
    credits: 250,
    planId: "PLN_9x8u1w2o872f5k6"
  },
  {
    id: 20,
    price: 8000,
    credits: 600,
    planId: "PLN_60dhgoist6mc0p6"
  },
  {
    id: 30,
    price: 12000,
    credits: 1200,
    planId: "PLN_2ghkp1knkcdzozg"
  },
  {
    id: 40,
    price: 48000,
    credits: 6000,
    planId: "PLN_ri3nf3da1ghv2ny"
  },
  {
    id: 50,
    price: 72000,
    credits: 12000,
    planId: "PLN_d2q4halqb3s7er7"
  }
];
export const yearlyPaymentPlans: PaymentPlan[] = [
  {
    id: 10,
    price: 4000,
    credits: 250,
    planId: process.env.REACT_APP_ANNUAL_PLAN_250
  },
  {
    id: 20,
    price: 8000,
    credits: 600,
    planId: process.env.REACT_APP_ANNUAL_PLAN_600
  },
  {
    id: 30,
    price: 12000,
    credits: 1200,
    planId: process.env.REACT_APP_ANNUAL_PLAN_1200
  },
  {
    id: 40,
    price: 48000,
    credits: 6000,
    planId: process.env.REACT_APP_ANNUAL_PLAN_6000
  },
  {
    id: 50,
    price: 72000,
    credits: 12000,
    planId: process.env.REACT_APP_ANNUAL_PLAN_12000
  }
];
