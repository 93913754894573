import React, { Component, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import ImageIcon from "@mui/icons-material/Image";
import Typography from "@mui/material/Typography";
// TODO: Remove
import { host } from "../../../../services/client";
import SettingsIcon from "@mui/icons-material/Settings";
import ClickAwayListener from "@mui/material/ClickAwayListener";

// Snackbar notification integration. REF: https://www.npmjs.com/package/material-ui-snackbar-provider
import { useSnackbar } from "notistack";

import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../../types/DocumentTemplate";

import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import {
  clearSelectedComponent,
  selectApprovalState,
  selectCurrentTemplateState,
  selectSelected,
  selectSelectedElementID,
  setSelectedComponent,
} from "../../editorSlice";
import validateContent from "../validation";
import { COMMIT_CHANGES_TIME_SEC, secondarySilver } from "../../../../globals";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ImageUploadForm from "./ImageUploadForm";
import { ViewPreferences } from "../../header/preferencesSlice";
import { useDrop } from "react-dnd";
import { DropSkeleton } from "../DropSkeleton";

import { Approvals } from "../Approvals";
import LibraryContentConfigFormSubSection from "../libraryContentSubSection/LibraryContentFormSubSection";
import { Tooltip } from "@mui/material";

import {
  findMatchingFont,
  getFontSize,
  handleDetachSelection,
} from "../HelperEditorFunctions";
import { attachedSubsectionElement } from "../utils";
import EditorUndoForm from "../CustomEditorDecorators/EditorUndoForm";
import SwitchSubsection from "../CustomEditorDecorators/SwitchSubSection";
import LibraryContentIcon from "../CustomEditorDecorators/LibraryContentIcon";
import { isIncludeImpact } from "../../drawers/right/Approval/ApprovalHelperFunctions/approvalHelper";
import { COLORS } from "../../../shared/Constants";
import DeleteConfirmForm from "../CustomEditorDecorators/DeleteConfirmForm";
import { shallowEqual } from "react-redux";
import ApprovalIcon from "../CustomEditorDecorators/ApporvalIcon";
import DeleteUnsharedConfirm from "../CustomEditorDecorators/DeleteUnsharedConfirm";
import { isUserFromShareLink } from "../TableElement/EditContent/helper";
import { CommentOutlined } from "@mui/icons-material";
import CommentsIcon from "src/features/comments/CommentsIcon";
/**
 * Image element editor.
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function ImageElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  parentSection__: DocumentSection | null;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  approvalsEnabled: boolean;
}) {
  const dispatch = useAppDispatch();
  const actions = useActions();
  const approvalState = useAppSelector(selectApprovalState);
  let countTemplateImages = 0;
  let countImageElement = 0;
  const documentTemplate = props.documentTemplate as DocumentTemplate;
  let exitLoop = false; // Flag variable to track if we need to exit from all loops

  // Labeled statement for the outermost loop
  outerLoop: if (documentTemplate.sections) {
    for (const section of documentTemplate.sections) {
      if (section.subsections) {
        for (const subsection of section.subsections) {
          if (
            Array.isArray(subsection.elements) && // Check if elements is an array
            subsection.elements.length > 0 &&
            subsection.elements[0]?.content_type === "IMAGE" && // Added optional chaining
            (props.approvalsEnabled || subsection.pending_deleted_at === null)
          ) {
            if (!exitLoop) {
              countTemplateImages++;
            }
            if (
              attachedSubsectionElement(subsection).id === props.element?.id
            ) {
              countImageElement++;
              exitLoop = true; // Set the flag to true if condition is met
              break outerLoop; // Break out of all loops using the labeled statement
            } else if (
              JSON.parse(attachedSubsectionElement(subsection).content_format)
                .isNumberFormat === true
              // && JSON.parse(subsection.elements[0].content_format).isDisplayCaption==="true"
            ) {
              countImageElement++;
            }
          }
        }
      }
    }
  }

  const startElement = React.useMemo(() => {
    return JSON.parse(props.element!.content_format);
  }, []);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] =
    useState<boolean>(false);
  const [isDeleteUnsharedOpen, setIsDeleteUnsharedOpen] =
    useState<boolean>(false);

  const selectedCurrentTemplateState = useAppSelector(
    selectCurrentTemplateState,
    shallowEqual
  );
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [caption, setCaption] = React.useState<string>(
    startElement.caption ? startElement.caption : ""
  );
  const [imageSize, setImageSize] = React.useState<string>(startElement.size);
  const [customSize, setCustomSize] = React.useState<string>(
    startElement.custom
  );
  const [captionAlignment, setCaptionAlignment] = React.useState<string>(
    startElement.captionAlignment
  );
  const [isDisplayCaption, setIsDisplayCaption] = React.useState<boolean>(
    startElement.DisplyCaption ? startElement.DisplyCaption : false
  );
  const [captionOrder, setCaptionOrder] = React.useState<string>(
    startElement.captionOrder ? startElement.captionOrder : "below"
  );
  const [justify, setJustify] = React.useState<string>(startElement.justify);
  const [isUploadFormOpen, setIsUploadFormOpen] =
    React.useState<boolean>(false);
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(false);
  const [droppedImage, setDroppedImage] = React.useState<File | null>(null);
  const [color, setColor] = React.useState<string>(
    startElement.color ? startElement.color : "000000"
  );
  const [isNumberFormat, setIsNumberFormat] = React.useState<boolean>(
    startElement.isNumberFormat ? startElement.isNumberFormat : false
  );
  const [isBold, setIsBold] = React.useState<boolean>(
    startElement.isBold ? startElement.isBold : false
  );
  const [isItalic, setIsItalic] = React.useState<boolean>(
    startElement.isItalic ? startElement.isItalic : false
  );
  const [isUnderline, setIsUnderline] = React.useState<boolean>(
    startElement.isUnderline ? startElement.isUnderline : false
  );

  const [isStandard, setIsStandard] = React.useState<boolean>(
    startElement.isStandard ? startElement.isStandard : false
  );

  const [handleWidth, sethandleWidth] = React.useState<number>(150);
  const [handleHeight, sethandleHeight] = React.useState<number>(75);
  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(() => ({
        accept: ["right-drawer-item", "image/*"],
        // drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
        drop: (dropItem: any) => {
          if (dropItem.file instanceof File) {
            // Handle the dropped image file here
            // For example, you can save it or process it
            // Call the function to set isUploadFormOpen to true
            setIsUploadFormOpen(true);
          } else {
            // Handle other dropped items, if needed
            addElementToSection({ ...dropItem.element });
          }
        },

        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }));

  function addElementToSection(element: DocumentElement) {
    if (element.content_type === "LIBRARYCONTENTSUBSECTION") {
      SetIsLibarayOpen(true);
    } else {
      actions.createSubsectionWithElement({
        section: props.parentSection as DocumentSection,
        documentElement: element,
        subsectionAbove: props.parentSubsection!,
      });
    }
  }
  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */
  function handleOnFocus(e: any): any {
    setIsFocused(true);

    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection,
        selectedComponent: props.element,
      })
    );
  }
  /**
   * Similarly track when focus is lost.
   * @param {*} e
   */
  function handleOnBlur(e: any) {
    setIsFocused(false);
    dispatch(clearSelectedComponent());
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length > 0) {
      const imageFile = droppedFiles[0];
      if (imageFile.type.startsWith("image/")) {
        setDroppedImage(imageFile);
        setIsUploadFormOpen(true);
      }
    }
  }
  const [font, setFont] = React.useState<string>(
    startElement.font ? startElement.font : "rmfamily"
  );
  const handleData = (data: any) => {
    setCaption(data.caption);
    setIsNumberFormat(data.isNumberFormat);
    setImageSize(data.imageSize);
    setCaptionAlignment(data.captionAlignment);
    setCaptionOrder(data.captionOrder);
    setColor(data.color);
    setFont(data.font);
    setIsDisplayCaption(data.isDisplayCaption);
    setFontsize(data.fontSize);
    setCustomSize(data.customSize);
    setIsBold(data.isBold);
    setIsItalic(data.isItalic);
    setIsUnderline(data.isUnderline);
    setIsStandard(data.isStandard);
  };

  const [fontSize, setFontsize] = React.useState<string>(
    startElement.fontSize ? startElement.fontSize : "large"
  );

  const isDeleted = props.parentSubsection?.pending_deleted_at !== null;

  const imageName =
    props.element !== null
      ? JSON.parse(props.element.content_format).caption === "Image"
        ? JSON.parse(props.element.content_format).caption +
          " " +
          countTemplateImages
        : JSON.parse(props.element.content_format).caption
      : "No title";
  let content = (
    <Divider>
      {captionOrder === "above" && (
        <Box>
          <Typography
            sx={{
              fontFamily: findMatchingFont(font),

              fontSize: getFontSize(fontSize),

              fontStyle: isItalic ? "italic" : "normal",
              fontWeight: isBold ? "bold" : "normal",
              textDecoration: isUnderline ? "underline" : "none",
              color: "#" + color + "!important",
            }}
          >
            {isNumberFormat && isDisplayCaption && !props.readOnly
              ? "Figure " + countImageElement + ": " + imageName
              : // (imageName==="Image"? imageName + countTemplateImages:imageName  )
                imageName}
          </Typography>
        </Box>
      )}

      <IconButton
        onClick={(e) => {
          setIsUploadFormOpen(true);
        }}
      >
        <ImageIcon
          fontSize="large"
          color={isDeleted ? "disabled" : "primary"}
        ></ImageIcon>
      </IconButton>
      {captionOrder !== "above" && (
        <Box>
          <Typography
            sx={{
              // fontFamily: font,
              fontFamily: findMatchingFont(font),
              // font === "rmfamily"
              //   ? "serif"
              //   : font === "ttfamily"
              //   ? "monospace"
              //   : font === "sffamily"
              //   ? "sans-serif"
              //   : "inherit",
              fontSize: getFontSize(fontSize),

              fontStyle: isItalic ? "italic" : "normal",
              fontWeight: isBold ? "bold" : "normal",
              textDecoration: isUnderline ? "underline" : "none",
              color: "#" + color + "!important",
            }}
          >
            {isNumberFormat && isDisplayCaption && !props.readOnly
              ? "Figure " + countImageElement + ": " + imageName
              : imageName}
          </Typography>
        </Box>
      )}
    </Divider>
  );

  //If there is an image, use it.
  if (props.element?.content && props.element?.content !== "") {
    const imageUrl = `${host}/organizations/${props.organizationId}/document_templates/${props.documentTemplate.id}/document_images_png/${props.element?.content}/show.png`;
    const image = new Image();
    let adjustedWidth;
    let adjustedHeight;
    let desiredWidth;
    let desiredHeight;

    // let ImageWidth = image.width?  image.width:600 ;
    // let ImageHeight  = image.height? image.height:400;
    image.src = imageUrl;
    image.onload = () => {
      if (image.width > 0 && image.height > 0)
        // ImageWidth = image.width; // Use image.width instead of image.naturalWidth
        // ImageHeight = image.height; // Use image.height instead of image.naturalHeight
        // Continue with your code using the adjustedWidth and adjustedHeight
        sethandleWidth(image.naturalWidth);
      sethandleHeight(image.naturalHeight);
    };

    if (imageSize === "full Page") {
      adjustedWidth = "100%";
      adjustedHeight = "auto";
    } else if (
      imageSize === "custom" &&
      customSize &&
      !isNaN(parseInt(customSize))
    ) {
      desiredWidth = image.naturalWidth;
      desiredHeight = image.naturalHeight;
      const scaledWidth = Math.min(parseInt(customSize), 200); // Convert customSize to a number and limit it to a maximum of 200
      const scaleFactor = 3; // Adjust this value to increase or decrease the scaling effect
      const scaledHeight = (scaledWidth * desiredHeight) / desiredWidth; // Calculate the proportional height based on the custom width
      adjustedWidth = `${scaledWidth * scaleFactor}px`;
      adjustedHeight = `${scaledHeight * scaleFactor}px`;
    } else if (imageSize === "Original") {
      // desiredWidth = ImageWidth; // Use image.width instead of image.naturalWidth
      // desiredHeight = ImageHeight; // Use image.height instead of image.naturalHeight

      desiredWidth = handleWidth;
      desiredHeight = handleHeight;

      const maxWidth = 600; // Maximum width for the image
      const maxHeight = 400; // Maximum height for the image
      // Maintain the aspect ratio
      if (desiredWidth > maxWidth || desiredHeight > maxHeight) {
        const widthRatio = desiredWidth / maxWidth;
        const heightRatio = desiredHeight / maxHeight;
        const maxRatio = Math.max(widthRatio, heightRatio);
        desiredWidth = Math.floor(desiredWidth / maxRatio);
        desiredHeight = Math.floor(desiredHeight / maxRatio);
      }
      adjustedWidth = desiredWidth;
      adjustedHeight = desiredHeight;
    } else {
      desiredWidth = image.naturalWidth;
      desiredHeight = image.naturalHeight;
      const maxWidth = 600; // Maximum width for the image
      const maxHeight = 400; // Maximum height for the image

      // Adjust the dimensions if they exceed the maximum values
      if (desiredWidth > maxWidth) {
        const ratio = maxWidth / desiredWidth;
        desiredWidth = maxWidth;
        desiredHeight = Math.floor(desiredHeight * ratio);
      }

      if (desiredHeight > maxHeight) {
        const ratio = maxHeight / desiredHeight;
        desiredHeight = maxHeight;
        desiredWidth = Math.floor(desiredWidth * ratio);
      }

      adjustedWidth = desiredWidth;
      adjustedHeight = desiredHeight;
    }

    content = (
      <Box
        sx={{
          // width: adjustedWidth,
          maxWidth: "600px",
          justifyContent: captionAlignment,
          display: "flex",
          flexDirection: "column", // Adjusting the layout to a column

          // Adjust other properties as needed
        }}
      >
        {captionOrder === "above" && (
          <>
            <Typography
              sx={{
                // fontFamily: font ,
                fontFamily: findMatchingFont(font),
                fontSize: getFontSize(fontSize),
                fontStyle: isItalic ? "italic" : "normal",
                fontWeight: isBold ? "bold" : "normal",
                textDecoration: isUnderline ? "underline" : "none",
                color: "#" + color + "!important",
                paddingBottom: 2,
              }}
            >
              {isNumberFormat && isDisplayCaption && !props.readOnly
                ? "Figure " + countImageElement + ": " + imageName
                : isDisplayCaption
                ? imageName
                : ""}
            </Typography>

            <img
              src={imageUrl}
              alt={"Image" + props.element.id}
              width={adjustedWidth}
              height={adjustedHeight}
              onClick={(e) => {
                if (props.approvalsEnabled) {
                  handleOnFocus(e);
                } else {
                  setIsUploadFormOpen(true);
                }
              }}
              style={{ justifyContent: justify }} // Adjusting the image alignment
            />
          </>
        )}
        {captionOrder !== "above" && (
          <>
            <Box sx={{ display: "flex", justifyContent: justify }}>
              <img
                alt={"Image" + props.element.id}
                src={imageUrl}
                width={adjustedWidth}
                height={adjustedHeight}
                onClick={(e) => {
                  if (props.approvalsEnabled) {
                    handleOnFocus(e);
                  } else {
                    setIsUploadFormOpen(true);
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: captionAlignment,
                paddingTop: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: findMatchingFont(font),
                  fontSize: getFontSize(fontSize),
                  fontStyle: isItalic ? "italic" : "normal",
                  fontWeight: isBold ? "bold" : "normal",
                  textDecoration: isUnderline ? "underline" : "none",
                  color: "#" + color + "!important",
                }}
              >
                {isNumberFormat && isDisplayCaption && !props.readOnly
                  ? "Figure " + countImageElement + ": " + imageName
                  : isDisplayCaption
                  ? imageName
                  : ""}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    );
  }

  const selected = useAppSelector(selectSelectedElementID, shallowEqual);
  const isSelected =
    props.element === null || selected.documentElement === null
      ? false
      : selected.documentElement == props.element!.id;

  function border(): any {
    if (
      props.parentSubsection?.pending_deleted_at != null ||
      props.parentSection.pending_deleted_at != null
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderRadius: 3,
        borderStyle: isSelected ? "solid" : "dashed",
        borderColor: COLORS.NOMIA_RED,
      };
    }

    if (
      props.parentSubsection &&
      props.approvalsEnabled &&
      isIncludeImpact(approvalState, props.parentSubsection.id)
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_ORANGE,
        borderStyle: isSelected ? "solid" : "dashed",
        // backgroundColor:  secondarySilver,
      };
    }

    if (
      props.parentSubsection &&
      props.approvalsEnabled &&
      props.element?.element_approvals
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_ORANGE,
        borderStyle: isSelected ? "solid" : "dashed",
        // backgroundColor:  secondarySilver,
      };
    }

    /// if the subsection is addded show green border to the Approver
    if (
      props.approvalsEnabled &&
      props.element !== null &&
      props.element.should_show
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_GREEN,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (props.viewPreferences.showSections) {
      return {
        borderRadius: 0,
        border: isSelected
          ? `1px solid ${secondarySilver}`
          : `1px dashed ${secondarySilver}`, // Add border property
        borderColor: secondarySilver, // Add border color property
      };
    }
    return {};
  }

  function handleDeleteClicked(e: any) {
    setIsDeleteUnsharedOpen(true);
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={(e) => {
          //     if(props.approvalsEnabled){
          //       handleOnBlur(e)
          //     }
        }}
      >
        <Box
          ref={drop}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          sx={Object.assign(
            {
              padding: 1,
              margin: 1, // i addes
              // REF: https://mui.com/system/borders/
              borderRadius: 3,
              flexGrow: 1,

              overflow: "visible",
              textAlign: justify,
              position: "relative",
              backgroundColor: "white",
            },
            { textAlign: captionAlignment },
            border()
          )}
          onDrop={(e) => handleDrop(e)}
        >
          <Box
            sx={{
              position: "relative",
              //  "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Box
              sx={{
                position: "relative",
                zIndex: 999,
              }}
            >
              <SwitchSubsection
                parentSection={props.parentSection as DocumentSection}
                parentSubsection={props.parentSubsection as DocumentSubsection}
                direction={"up"}
                isDisabled={false}
                element={props.element as DocumentElement}
                style={{
                  padding: 0,
                }}
                readonly={props.readOnly}
              />

              <LibraryContentIcon
                isHeading={false}
                parentSection={props.parentSection as DocumentSection}
                parentSubsection={props.parentSubsection as DocumentSubsection}
                documentTemplate={props.documentTemplate as DocumentTemplate}
                style={{
                  outline: "none",
                  color: "#7284A3",
                  position: "absolute",
                  top: 6,
                  right: 52,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                }}
              />

              {props.approvalsEnabled && (
                <ApprovalIcon
                  isHeading={false}
                  parentSection={props.parentSection as DocumentSection}
                  parentSubsection={
                    props.parentSubsection as DocumentSubsection
                  }
                  approvalState={approvalState}
                  style={{
                    outline: "none",
                    color: "#7284A3",
                    position: "absolute",
                    top: 6,
                    left: -46,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                  }}
                />
              )}

              {!props.approvalsEnabled && !props.readOnly ? (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 25,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    if (
                      !!(props.documentTemplate as DocumentTemplate)
                        .included_subSections?.[props.parentSubsection!.id] ||
                      !!(props.documentTemplate as DocumentTemplate)
                        .imported_sub_sections?.[props.parentSubsection!.id]
                    ) {
                      if (
                        selectedCurrentTemplateState.approval ||
                        selectedCurrentTemplateState.editing ||
                        !selectedCurrentTemplateState.draft
                      ) {
                        setIsDeleteConfirmOpen(true);
                      } else {
                        handleDetachSelection(props, actions, dispatch);
                      }
                    } else {
                      handleDeleteClicked(e);
                    }
                  }}
                  disabled={props.approvalsEnabled}
                >
                  <DeleteIcon
                    style={{
                      fontSize: 20,
                      outline: "none",
                      color: "#7284A3",
                    }}
                  />
                </IconButton>
              ) : isUserFromShareLink() ? (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 2,
                    right: 2,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                  }}
                >
                  <CommentsIcon
                    totalComments={113}
                    onClick={() => {
                      alert();
                    }}
                  />
                </IconButton>
              ) : null}

              {!props.approvalsEnabled && !props.readOnly && (
                <Tooltip title="Image Settings" placement="top">
                  <IconButton
                    onMouseDown={(e) => e.preventDefault()}
                    disabled={props.approvalsEnabled}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsUploadFormOpen(true);
                      e.stopPropagation(); // Add this line to stop event propagation
                    }}
                    style={{
                      position: "absolute",
                      top: 6,
                      right: 3,
                      transition: "none", // Remove transition on button press
                      margin: 0,
                      padding: 0,
                      width: "fit-content",
                      zIndex: 99999,
                    }}
                  >
                    <SettingsIcon
                      style={{
                        fontSize: 20,
                        outline: "none",
                        color: "#7284A3",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}

              {isDeleted && props.approvalsEnabled && !props.readOnly && (
                <IconButton
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteOpen(true);
                    e.stopPropagation(); // Add this line to stop event propagation
                  }}
                  title="Undo Deletion"
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 3,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99999,
                  }}
                >
                  <UndoIcon
                    style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                  />
                </IconButton>
              )}
            </Box>
          </Box>

          <Box
            id={`subsection-${props.parentSubsection!.id}`}
            onBlur={(e: any) => {
              if (props.approvalsEnabled) {
                handleOnBlur(e);
              }
            }}
            onClick={(e) => {
              if (props.approvalsEnabled) {
                dispatch(
                  setSelectedComponent({
                    selectedSection: props.parentSection as DocumentSection,
                    selectedSubsection: props.parentSubsection,
                    selectedComponent: props.element,
                  })
                );
                handleOnFocus(e);
              }

              if (!props.readOnly) {
                if (!props.approvalsEnabled) {
                  e.preventDefault();
                  setIsUploadFormOpen(true);
                  e.stopPropagation(); // Add this line to stop event propagation
                } else {
                  handleOnFocus(e);
                }
              }
            }}
            // onMouseDown={(e) => e.preventDefault()}
            sx={{ paddingTop: 4 }}
          >
            {" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: justify,
              }}
            >
              {content}
            </Box>
          </Box>

          <SwitchSubsection
            parentSection={props.parentSection as DocumentSection}
            parentSubsection={props.parentSubsection as DocumentSubsection}
            direction={"down"}
            isDisabled={false}
            element={props.element as DocumentElement}
            style={{
              paddingTop: 4,
            }}
            readonly={props.readOnly}
          />
          {isUploadFormOpen && !props.readOnly && !props.approvalsEnabled ? (
            <ImageUploadForm
              closeDialogHandler={() => {
                setIsUploadFormOpen(false);
                setDroppedImage(null);
              }}
              section={props.parentSection as DocumentSection}
              subSection={props.parentSubsection!}
              element={props.element!}
              documentTemplate={props.documentTemplate as DocumentTemplate}
              organizationId={props.organizationId}
              setJustify={setJustify}
              imageFile={droppedImage}
              sendData={handleData}
              imageNumber={countTemplateImages}
            />
          ) : null}

          {/* The drag+drop element showing that you are going to drop something here. */}
        </Box>
      </ClickAwayListener>
      {isOver ? <DropSkeleton /> : null}
      {IsLibraryOpen && (
        <LibraryContentConfigFormSubSection
          closeDialogHandler={() => SetIsLibarayOpen(false)}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          viewPreferences={props.viewPreferences}
        />
      )}
      {isDeleteConfirmOpen === true ? (
        <DeleteConfirmForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteConfirmOpen(false)}
        />
      ) : null}

      {isDeleteUnsharedOpen === true ? (
        <DeleteUnsharedConfirm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteUnsharedOpen(false)}
        />
      ) : null}

      {isDeleteOpen === true ? (
        <EditorUndoForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteOpen(false)}
        />
      ) : null}
    </>
  );
}
