import { AccountCircle, AccountCircleOutlined } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import React from "react";

type Props = {
  item: any;
};

const CommentsCard = (props: Props) => {
  return (
    <Box>
      <Box
        // display={"flex"}
        alignItems={"center"}
        padding={"2px"}
        paddingTop={"4px"}
        position={"sticky"}
        top={0}
        bgcolor={"#FFFFFF"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <AccountCircle style={{ color: "gray" }} />
          <Typography fontSize={"10px"} fontWeight={"bold"}>
            Haseeb Manzoor
          </Typography>
        </Box>
      </Box>
      <Typography
        fontSize={"11px"}
        textAlign={"justify"}
        margin={"3px"}
        padding={"2px"}
      >
        Comments Will Come Here For A Couple Of Great Reasons And Valued
        Resources Managers And Variables As Well Comments Will Come Here For A
        Couple Of Great Reasons And Valued Resources Managers And Variables As
        Well Comments Will Come Here For A Couple Of Great Reasons And Valued
        Resources Managers And Variables As Well Comments Will Come Here For A
        Couple Of Great Reasons And Valued Resources Managers And Variables As
        Well Comments Will Come Here For A Couple Of Great Reasons And Valued
        Resources Managers And Variables As Well Comments Will Come Here For A
        Couple Of Great Reasons And Valued Resources Managers And Variables As
        Well Comments Will Come Here For A Couple Of Great Reasons And Valued
        Resources Managers And Variables As Well Comments Will Come Here For A
        Couple Of Great Reasons And Valued Resources Managers And Variables As
        Well Comments Will Come Here For A Couple Of Great Reasons And Valued
      </Typography>
      <Divider />
    </Box>
  );
};

export default CommentsCard;
