import { takeEvery, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import {
  changePassword,
  finalizeReset,
  finalizeResetWithUserDetails,
  login,
  signup,
  reset,
  resetUserPassword,
} from "./sagas/auth";
import {
  createUserInOrg,
  createUserWithOrg,
  createAdminUserInOrg,
  createSuperUserInOrg,
  getOrganizationUsers,
  getOrganizations,
  setOrganization,
  updateOrganizationCredits,
  deleteOrganization,
  deleteSuperUser,
} from "./sagas/organizations";
import {
  attachUser,
  changeUserRole,
  createUser,
  createWorkspace,
  deleteWorkspace,
  updateWorkspace,
  detachUser,
  detachOrganizationUser,
  updateOrganizationUserRole,
  getWorkspace,
  getWorkspaceSummary,
  getWorkspaceSummarySearch,
  getWorkspaceSummaryDocument,
  getWorkspaces,
  setCurrentWorkspace,
} from "./sagas/workspaces";
import {
  approvalPublishDocumentTemplate,
  cloneDocumentTemplate,
  createDocumentTemplate,
  deleteDocumentRecord,
  deleteDocumentTemplate,
  executeDocumentTemplateImport,
  favouriteDocumentTemplate,
  generateDocumentTemplate,
  getDocumentImagePreview,
  getDocumentTemplateVariables,
  getDocumentTemplates,
  getRecordImagePreview,
  importDocumentTemplate,
  importDocumentTemplateFromDoc,
  makeNormalDocumentTemplate,
  draftCollaborationDocumentTemplate,
  moveDocumentTemplate,
  previewDocumentTemplate,
  publishDocumentTemplate,
  undeleteDocumentRecord,
  undeleteDocumentTemplate,
  unfavouriteDocumentTemplate,
  updateDocumentTemplate,
  updateDocumentTemplateImportText,
  againPublishDocumentTemplate,
  approvalImpactChanges,
} from "./sagas/documentTemplates";
import {
  createBulkDocumentRecord,
  createDocumentRecord,
  getDocumentRecords,
  previewDocumentRecord,
} from "./sagas/documentRecords";
import {
  approveDocumentSection,
  attachSection,
  attachVersionDocumentElement,
  createReference,
  createSection,
  createSectionAboveSection,
  createSectionWithElement,
  createSubsectionWithElement,
  createVariable,
  deleteReference,
  deleteSection,
  deleteSectionAllDocuments,
  deleteSubsection,
  deleteVariable,
  enableApprovalModeSaga,
  getReferences,
  getVariables,
  refreshDocumentTemplate,
  setEditingDocumentTemplate,
  fetchDocumentTemplate,
  setHeadingLevel,
  setOpenApprovalDocumentTemplate,
  switchSections,
  switchSubsections,
  switchSubsectionBlock,
  undoDeletedSection,
  undoDeletedSubsection,
  updateDocumentElement,
  updateDocumentElementContent,
  updateReference,
  updateSection,
  updateSectionContent,
  updateSectionHeadingLevels,
  updateVariable,
  uploadDocumentElementImage,
} from "./sagas/editor";
import {
  getDocumentMaster,
  getSectionDocumentMaster,
  updateDocumentMaster,
  updateSectionDocumentMaster,
  createDocumentMaster,
  updateWorkspaceDocumentMaster,
} from "./sagas/documentMaster";

export function* rootSaga() {
  yield takeLatest(actions.login.type, login);
  yield takeLatest(actions.signup.type, signup);
  yield takeLatest(actions.reset.type, reset);
  yield takeLatest(actions.resetUserPassword.type, resetUserPassword);
  yield takeLatest(actions.finalizeReset.type, finalizeReset);
  yield takeLatest(
    actions.finalizeResetWithUserDetails.type,
    finalizeResetWithUserDetails
  );
  yield takeLatest(actions.changePassword.type, changePassword);
  yield takeLatest(actions.getOrganizations.type, getOrganizations);
  yield takeLatest(actions.setOrganization.type, setOrganization);
  yield takeLatest(actions.getOrganizationUsers.type, getOrganizationUsers);
  yield takeLatest(
    actions.refreshDocumentTemplate.type,
    refreshDocumentTemplate
  );
  yield takeLatest(actions.getWorkspace.type, getWorkspace);
  yield takeLatest(actions.getWorkspaces.type, getWorkspaces);
  yield takeLatest(actions.getWorkspaceSummary.type, getWorkspaceSummary);
  yield takeLatest(
    actions.getWorkspaceSummarySearch.type,
    getWorkspaceSummarySearch
  );
  yield takeLatest(
    actions.getWorkspaceSummaryDocument.type,
    getWorkspaceSummaryDocument
  );
  yield takeLatest(actions.setWorkspace.type, setCurrentWorkspace);
  yield takeLatest(actions.createWorkspace.type, createWorkspace);
  yield takeLatest(actions.deleteWorkspace.type, deleteWorkspace),
    yield takeLatest(actions.updateWorkspace.type, updateWorkspace),
    yield takeLatest(actions.attachUser.type, attachUser);
  yield takeLatest(actions.createUser.type, createUser);
  yield takeLatest(actions.createUserInOrg.type, createUserInOrg);
  yield takeLatest(actions.createUserWithOrg.type, createUserWithOrg);
  yield takeLatest(
    actions.updateOrganizationCredits.type,
    updateOrganizationCredits
  );
  yield takeLatest(actions.deleteOrganization.type, deleteOrganization);
  yield takeLatest(actions.deleteSuperUser.type, deleteSuperUser);
  yield takeLatest(actions.createAdminUserInOrg.type, createAdminUserInOrg);
  yield takeLatest(actions.createSuperUserInOrg.type, createSuperUserInOrg);
  yield takeLatest(actions.detachUser.type, detachUser);
  yield takeLatest(actions.detachOrganizationUser.type, detachOrganizationUser);
  yield takeLatest(
    actions.updateOrganizationUserRole.type,
    updateOrganizationUserRole
  );
  yield takeLatest(actions.changeUserRole.type, changeUserRole);
  yield takeLatest(actions.approveDocumentSection.type, approveDocumentSection);

  yield takeLatest(actions.getDocumentTemplates.type, getDocumentTemplates);
  yield takeLatest(actions.createDocumentTemplate.type, createDocumentTemplate);
  yield takeLatest(
    actions.getDocumentTemplateVariables.type,
    getDocumentTemplateVariables
  );
  yield takeLatest(actions.updateDocumentTemplate.type, updateDocumentTemplate);
  yield takeLatest(
    actions.updateDocumentTemplateImportText.type,
    updateDocumentTemplateImportText
  );
  yield takeLatest(
    actions.executeDocumentTemplateImport.type,
    executeDocumentTemplateImport
  );
  yield takeLatest(
    actions.setEditingDocumentTemplate.type,
    setEditingDocumentTemplate
  );
  yield takeLatest(actions.fetchDocumentTemplate.type, fetchDocumentTemplate);

  yield takeLatest(
    actions.setOpenApprovalDocumentTemplate.type,
    setOpenApprovalDocumentTemplate
  );

  yield takeLatest(
    actions.previewDocumentTemplate.type,
    previewDocumentTemplate
  );
  yield takeLatest(
    actions.generateDocumentTemplate.type,
    generateDocumentTemplate
  );
  yield takeLatest(actions.previewDocumentRecord.type, previewDocumentRecord);
  yield takeLatest(
    actions.publishDocumentTemplate.type,
    publishDocumentTemplate
  );

  yield takeLatest(
    actions.againPublishDocumentTemplate.type,
    againPublishDocumentTemplate
  );

  yield takeLatest(
    actions.approvalPublishDocumentTemplate.type,
    approvalPublishDocumentTemplate
  );

  yield takeLatest(actions.approvalImpactChanges.type, approvalImpactChanges);

  yield takeLatest(actions.createDocumentRecord.type, createDocumentRecord);
  yield takeLatest(actions.getDocumentRecords.type, getDocumentRecords);
  yield takeLatest(
    actions.createBulkDocumentRecord.type,
    createBulkDocumentRecord
  );

  // NOTE: We are taking every instance as this can be fired in batches.
  yield takeEvery(
    actions.getDocumentImagePreview.type,
    getDocumentImagePreview
  );

  yield takeEvery(actions.getRecordImagePreview.type, getRecordImagePreview);

  yield takeLatest(actions.deleteDocumentTemplate.type, deleteDocumentTemplate);
  yield takeLatest(actions.deleteDocumentRecord.type, deleteDocumentRecord);
  yield takeLatest(actions.cloneDocumentTemplate.type, cloneDocumentTemplate);
  yield takeLatest(actions.moveDocumentTemplate.type, moveDocumentTemplate);
  yield takeLatest(
    actions.makeNormalDocumentTemplate.type,
    makeNormalDocumentTemplate
  );
  yield takeLatest(
    actions.draftCollaborationDocumentTemplate.type,
    draftCollaborationDocumentTemplate
  );
  yield takeLatest(
    actions.undeleteDocumentTemplate.type,
    undeleteDocumentTemplate
  );
  yield takeLatest(actions.undeleteDocumentRecord.type, undeleteDocumentRecord);
  yield takeLatest(actions.importDocumentTemplate.type, importDocumentTemplate);
  yield takeLatest(
    actions.importDocumentTemplateFromDoc.type,
    importDocumentTemplateFromDoc
  );
  yield takeLatest(
    actions.favouriteDocumentTemplate.type,
    favouriteDocumentTemplate
  );
  yield takeLatest(
    actions.unfavouriteDocumentTemplate.type,
    unfavouriteDocumentTemplate
  );
  yield takeLatest(actions.enableApprovalMode.type, enableApprovalModeSaga);

  yield takeLatest(actions.createSection.type, createSection);
  yield takeLatest(
    actions.createSectionWithElement.type,
    createSectionWithElement
  );

  yield takeLatest(actions.attachSection.type, attachSection);
  yield takeLatest(actions.updateSection.type, updateSection);
  yield takeLatest(actions.updateSectionContent.type, updateSectionContent);

  yield takeLatest(
    actions.createSectionAboveSection.type,
    createSectionAboveSection
  );
  yield takeLatest(actions.switchSections.type, switchSections);
  yield takeLatest(actions.deleteSection.type, deleteSection);
  yield takeLatest(
    actions.deleteSectionAllDocuments.type,
    deleteSectionAllDocuments
  );
  yield takeLatest(actions.undoDeletedSection.type, undoDeletedSection);
  yield takeLatest(actions.undoDeletedSubsection.type, undoDeletedSubsection);

  yield takeLatest(
    actions.createSubsectionWithElement.type,
    createSubsectionWithElement
  );
  yield takeLatest(actions.updateDocumentElement.type, updateDocumentElement);
  yield takeLatest(
    actions.updateDocumentElementContent.type,
    updateDocumentElementContent
  );

  yield takeLatest(
    actions.attachVersionDocumentElement.type,
    attachVersionDocumentElement
  );
  yield takeLatest(actions.switchSubsections.type, switchSubsections);
  yield takeLatest(actions.switchSubsectionBlock.type, switchSubsectionBlock);
  yield takeLatest(actions.deleteSubsection.type, deleteSubsection);

  yield takeLatest(actions.getVariables.type, getVariables);
  yield takeLatest(actions.createVariable.type, createVariable);
  yield takeLatest(actions.updateVariable.type, updateVariable);
  yield takeLatest(actions.deleteVariable.type, deleteVariable);

  yield takeLatest(actions.getReferences.type, getReferences);
  yield takeLatest(actions.createReference.type, createReference);
  yield takeLatest(actions.updateReference.type, updateReference);
  yield takeLatest(actions.deleteReference.type, deleteReference);

  yield takeLatest(actions.getDocumentMaster.type, getDocumentMaster);
  yield takeLatest(
    actions.getSectionDocumentMaster.type,
    getSectionDocumentMaster
  );
  yield takeLatest(actions.updateDocumentMaster.type, updateDocumentMaster);
  yield takeLatest(
    actions.updateSectionDocumentMaster.type,
    updateSectionDocumentMaster
  );
  yield takeLatest(actions.createDocumentMaster.type, createDocumentMaster);
  yield takeLatest(
    actions.updateWorkspaceDocumentMaster.type,
    updateWorkspaceDocumentMaster
  );

  yield takeLatest(
    actions.uploadDocumentElementImage.type,
    uploadDocumentElementImage
  );

  yield takeLatest(
    actions.updateSectionHeadingLevels.type,
    updateSectionHeadingLevels
  );

  yield takeLatest(actions.setHeadingLevel.type, setHeadingLevel);
}
