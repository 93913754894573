import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutline } from "@mui/icons-material";

interface ShareDocModalProps {
  open: boolean;
  onClose: () => void;
  onSend: (emails: string[]) => void;
  emails: string[];
  setEmails: any;
}

const ShareDocModal: React.FC<ShareDocModalProps> = ({
  open,
  onClose,
  onSend,
  emails,
  setEmails,
}) => {
  const [errors, setErrors] = useState<string[]>([""]);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
    setErrors([...errors, ""]);
  };

  const handleRemoveEmail = (index: number) => {
    setEmails(emails.filter((_, i) => i !== index));
    setErrors(errors.filter((_, i) => i !== index));
  };

  const handleChangeEmail = (index: number, value: string) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    const newErrors = [...errors];
    newErrors[index] = validateEmail(value) ? "" : "Invalid email address";
    setErrors(newErrors);

    if (!value) {
      newErrors[index] = "";
      setErrors(newErrors);
    }
  };

  //   const invalidEmails = emails.filter((email) => !validateEmail(email));

  const handleSend = () => {
    onSend(emails);
    onClose(); // Close modal after sending
  };

  return (
    <Dialog open={open} maxWidth="xl" onClose={onClose}>
      <DialogTitle>Share Document</DialogTitle>
      <DialogContent style={{ paddingTop: "20px" }}>
        {emails.map((email, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            justifyItems={"center"}
            marginBottom={2}
          >
            <TextField
              InputProps={{
                style: { fontSize: 14 },
              }}
              fullWidth
              InputLabelProps={{
                style: { fontSize: 14 },
              }}
              label={`Email ${index + 1}`}
              variant="outlined"
              value={email}
              size="small"
              onChange={(e) => handleChangeEmail(index, e.target.value)}
              error={!!errors[index]}
              color={
                errors[index]
                  ? "error"
                  : validateEmail(emails[index])
                  ? "success"
                  : "primary"
              }
              //   helperText={errors[index]}
            />
            <IconButton
              size="small"
              onClick={() => handleRemoveEmail(index)}
              disabled={emails.length === 1}
            >
              <DeleteOutline />
            </IconButton>
          </Box>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddEmail}
          variant="outlined"
          fullWidth
        >
          Add More
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSend} color="primary" variant="contained">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDocModal;
