import React, { Component, useState } from "react";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";

import {
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";

import UndoIcon from "@mui/icons-material/Undo";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearSelectedComponent,
  selectDocumentState,
  setSelectedComponent,
} from "../editorSlice";
import Divider from "@mui/material/Divider";
import { ViewPreferences } from "../header/preferencesSlice";
import Typography from "@mui/material/Typography";
import { useDrop } from "react-dnd";
import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";

import { Approvals } from "./Approvals";
import LibraryContentConfigFormSubSection from "./libraryContentSubSection/LibraryContentFormSubSection";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { compareComponentOrder } from "../../../services/documentTemplatesAPI";
import SettingsIcon from "@mui/icons-material/Settings";
import { font_Families } from "../../../globals";
import {
  documentMasterFormat,
  findMatchingFont,
} from "./HelperEditorFunctions";
import EditorUndoForm from "./CustomEditorDecorators/EditorUndoForm";
import { COLORS } from "../../shared/Constants";
import { ColorPicker } from "./TableElement/ThemeFomattingSetting/CustomThemeConfig";
import { selectDocumentMasterState } from "../header/documentMasterSlice";
import { isUserFromShareLink } from "./TableElement/EditContent/helper";
import { CommentOutlined } from "@mui/icons-material";
import CommentsIcon from "src/features/comments/CommentsIcon";

/**
 * A list of figures and tables.
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function ListOfFiguresElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  parentSection__: DocumentSection | null;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  approvalsEnabled: boolean;
}) {
  const documentTemplate = props.documentTemplate as DocumentTemplate;
  const documentMasterState = useAppSelector(selectDocumentMasterState);
  const dispatch = useAppDispatch();
  const actions = useActions();
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [displayOnEditorScreen, setDisplayOnEditorScreen] =
    React.useState<boolean>(true);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const isDeleted =
    (props.parentSection.pending_deleted_at != null ||
      props.parentSubsection?.pending_deleted_at != null) &&
    props.approvalsEnabled;

  const startElement = React.useMemo(() => {
    return JSON.parse(props.element!.content_format);
  }, []);
  const [Title, setTitle] = React.useState<string>(startElement.title);
  const [font, setFont] = React.useState<string>(
    startElement ? startElement.font : "rmfamily"
  );
  const [fontSize, setFontsize] = React.useState<string>(startElement.fontSize);
  const [color, setColor] = React.useState<string>(startElement.color);
  const [lineSpacing, setLineSpacing] = React.useState<string>(
    startElement.lineSpacing
  );
  const selectedChoice: string = startElement.type;

  const [isPageBreakAbove, setIsPageBreakAbove] = React.useState<boolean>(
    startElement.isPageBreakAbove ? startElement.isPageBreakAbove : false
  );

  const [isPageBreakBelow, setIsPageBreakBelow] = React.useState<boolean>(
    startElement.isPageBreakAbove ? startElement.isPageBreakAbove : false
  );

  const [isFollowMaster, setIsFollowMaster] = React.useState<boolean>(
    startElement.isFollowDtm ? startElement.isFollowDtm : false
  );

  const [isBold, setIsBold] = React.useState<boolean>(
    startElement.isBold ? startElement.isBold : false
  );
  const [isItalic, setIsItalic] = React.useState<boolean>(
    startElement.isItalic ? startElement.isItalic : false
  );
  const [isUnderline, setIsUnderline] = React.useState<boolean>(
    startElement.isUnderline ? startElement.isUnderline : false
  );

  const [isStandard, setIsStandard] = React.useState<boolean>(
    startElement.isStandard ? startElement.isStandard : false
  );

  const [selectedStyles, setSelectedStyles] = useState<string[]>(() => {
    const initialSelectedStyles: string[] = [];
    if (isStandard) {
      initialSelectedStyles.push("standard");
    }
    if (isBold) {
      initialSelectedStyles.push("bold");
    }
    if (isItalic) {
      initialSelectedStyles.push("italic");
    }
    if (isUnderline) {
      initialSelectedStyles.push("underline");
    }
    return initialSelectedStyles;
  });
  //  Implemention  for Style Check Boxes
  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case "bold":
        setIsBold(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "italic":
        setIsItalic(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "underline":
        setIsUnderline(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "standard":
        setIsStandard(checked);
        setIsBold(false);
        setIsItalic(false);
        setIsUnderline(false);
        setSelectedStyles(checked ? ["standard"] : []);
        break;
      default:
        break;
    }
  };

  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(() => ({
        accept: "right-drawer-item",
        drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }));
  function addElementToSection(element: DocumentElement) {
    if (element.content_type === "LIBRARYCONTENTSUBSECTION") {
      SetIsLibarayOpen(true);
    } else {
      actions.createSubsectionWithElement({
        section: props.parentSection as DocumentSection,
        documentElement: element,
        subsectionAbove: props.parentSubsection!,
      });
    }
  }
  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
    resetValueToProps();
  }
  // !RESET Values to default values
  function resetValueToProps() {
    setTitle(startElement.title);
    setFont(startElement.font);
    setFontsize(startElement.fontSize);
    setColor(startElement.color);
    setLineSpacing(startElement.lineSpacing);
    setIsBold(startElement.isBold);
    setIsItalic(startElement.isItalic);
    setIsUnderline(startElement.isUnderLine);
    setIsFollowMaster(startElement.isFollowDtm);
  }
  function handleDialogSave() {
    const newParms = JSON.stringify({
      title: Title,
      font: font,
      fontSize: fontSize,
      color: color,
      lineSpacing: lineSpacing,
      type: selectedChoice,
      isPageBreakBelow: isPageBreakBelow,
      isPageBreakAbove: isPageBreakAbove,
      isFollowDtm: isFollowMaster,
      isBold: isBold,
      isItalic: isItalic,
      isUnderline: isUnderline,
      isStandard: isStandard,
    });
    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection as DocumentSubsection,
      documentElement: {
        ...props.element,
        content_format: newParms,
      } as DocumentElement,
    });

    setDialogOpen(false);
  }

  const fontFamilies = font_Families;
  const colorOptions = [
    "black",
    "white",
    "red",
    "blue",
    "green",
    "yellow",
    "purple",
    "orange",
  ];
  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */
  function handleOnFocus(e: any): any {
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection,
        selectedComponent: props.element,
      })
    );
  }
  /**
   * Similarly track when focus is lost.
   * @param {*} e
   */
  function handleOnBlur(e: any) {
    dispatch(clearSelectedComponent());
  }

  function handleDeleteClicked(e: any) {
    if (props.parentSection) {
      actions.deleteSection(props.parentSection as DocumentSection);
    }
    dispatch(clearSelectedComponent());
  }
  let countTableElement = 0;
  const desiredFont = React.useMemo(() => {
    return findMatchingFont(font);
  }, [font]);

  const h1Formating = documentMasterFormat(documentMasterState, "HEADING", 1);
  const h2Formating = documentMasterFormat(documentMasterState, "HEADING", 2);

  const style = {
    paddingLeft: "25px",
    lineHeight: isFollowMaster ? 1 : lineSpacing,
    fontSize: isFollowMaster
      ? (h2Formating.fontSize as number) * 0.85 + "pt"
      : `${fontSize}pt`, // Set the font size
    // color: color, // Set the text color
    color: isFollowMaster ? "#" + h2Formating.fontColor : "#" + color, //color, // Set the text color
    fontFamily: isFollowMaster
      ? findMatchingFont(h2Formating.font)
      : findMatchingFont(font),
    fontStyle:
      isFollowMaster && h2Formating.fontStyle.includes("italic")
        ? "italic"
        : isItalic
        ? "italic"
        : "normal",
    textDecoration:
      isFollowMaster && h2Formating.fontStyle.includes("underline")
        ? "underline"
        : isUnderline
        ? "underline"
        : "none",
    fontWeight:
      isFollowMaster && h2Formating.fontStyle.includes("bold")
        ? "bold"
        : isBold
        ? "bold"
        : "normal",
  };

  const h1Style = {
    fontStyle: h1Formating.fontStyle.includes("italic") ? "italic" : "normal",
    textDecoration: h1Formating.fontStyle.includes("underline")
      ? "underline"
      : "none",
    fontWeight: h1Formating.fontStyle.includes("bold") ? "bold" : "normal",
    fontSize: (h1Formating.fontSize as number) + "pt",
    fontFamily: findMatchingFont(h1Formating.font),
    color: "#" + h1Formating.fontColor, //color, // Set the text color
    marginBottom: "16px", // Add some bottom margin
  };

  return (
    <>
      <Box
        ref={drop}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        sx={{
          padding: 1,
          mx: 1,
          margin: 0,
        }}
        id={`section-${props.parentSection.id}`}
      >
        {/* this Hide the Delete Button */}
        {/* <Approvals
          approvalsEnabled={props.approvalsEnabled}
          element={props.element}
          parentSubsection={props.parentSubsection}
          parentSection={props.parentSection__}
        /> */}
        {props.viewPreferences.showIDs ? (
          <DebugID
            name="Element"
            id={props.element !== null ? props.element.id : -1}
            componentOrder={0}
          />
        ) : null}
        {/* <Box sx={{ mx: 14, paddingBottom: "19px" }}>
          <Box
            style={{
              zIndex: "99",
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin:'0px',
              marginTop: "10px",

            }}
          >
            <Button variant="outlined" size="small" onClick={handleDialogOpen}>
              Edit List of{" "}
              {selectedChoice === "List of Tables" ? "Tables" : "Figures"}
            </Button>
          </Box>
        </Box> */}
        {selectedChoice === "List of Tables" && displayOnEditorScreen && (
          <Box
            sx={{
              border: isDeleted
                ? "2px dashed Red !important"
                : props.element?.should_show && props.approvalsEnabled
                ? `5px dashed ${COLORS.NOMIA_GREEN}`
                : "2px solid silver.main",
              borderColor: isDeleted
                ? "Red !important"
                : props.element?.should_show && props.approvalsEnabled
                ? COLORS.NOMIA_GREEN
                : "",
              margin: 0,
              padding: "10px",
              paddingTop: "3px",
              paddingLeft: "10px",
              // fontFamily: font, // Set the font style
              fontFamily: desiredFont,
              lineHeight: lineSpacing, // Set the line spacing
              backgroundColor: "#F7F7F7", // Set the background color
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",

                zIndex: 99,
              }}
            >
              {!props.approvalsEnabled && !isUserFromShareLink() ? (
                <>
                  <Tooltip title="" placement="top">
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 20,
                        transition: "none", // Remove transition on button press
                        margin: 0,
                        padding: 0,
                        width: "fit-content",
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={handleDeleteClicked}
                    >
                      <DeleteIcon
                        style={{
                          fontSize: 22,
                          outline: "none",
                          color: "#7284A3",
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="List of Tables Settings" placement="top">
                    <IconButton
                      onClick={handleDialogOpen}
                      style={{
                        position: "absolute",
                        top: 2,
                        right: -5,
                        transition: "none", // Remove transition on button press
                        margin: 0,
                        padding: 0,
                        width: "fit-content",
                      }}
                    >
                      <SettingsIcon
                        style={{
                          fontSize: 22,
                          outline: "none",
                          color: "#7284A3",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              ) : isUserFromShareLink() ? (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 15,
                    transition: "none",
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99,
                  }}
                >
                  <CommentsIcon
                    totalComments={113}
                    onClick={() => {
                      alert();
                    }}
                  />
                </IconButton>
              ) : null}

              {isDeleted && props.approvalsEnabled && (
                <IconButton
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteOpen(true);
                    e.stopPropagation(); // Add this line to stop event propagation
                  }}
                  title="Undo Deletion"
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 3,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99999,
                  }}
                >
                  <UndoIcon
                    style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                  />
                </IconButton>
              )}
            </Box>
            <span
              style={{
                ...h1Style,
              }}
            >
              {Title}
            </span>

            {documentTemplate?.sections?.map((section, index) => (
              <div key={index}>
                {section.subsections?.map((subsection, subIndex) => (
                  <div key={subIndex}>
                    {subsection.elements?.map((element, elIndex) => {
                      if (
                        Array.isArray(subsection.elements) && // Check if elements is an array
                        subsection.elements.length > 0 &&
                        subsection.elements[0]?.content_type === "TABLE" && // Added optional chaining
                        JSON.parse(subsection.elements[0].content_format)
                          .displayCaption === true
                      ) {
                        const caption = JSON.parse(
                          subsection.elements[0].content_format
                        ).caption;
                        countTableElement++;
                        return (
                          <p
                            key={elIndex}
                            style={{
                              // paddingLeft: "25px",
                              // fontSize: `${fontSize}px`, // Set the font size
                              // color: color, // Set the text color
                              // fontWeight: "bold",

                              ...style,
                              // marginLeft: isIndented ? "20px" : "0", // Conditionally apply indent for subsections
                            }}
                          >
                            {countTableElement +
                              Array(4).fill("\u00A0").join("") +
                              caption}
                          </p>
                        );
                      }
                      return null;
                    })}
                  </div>
                ))}
              </div>
            ))}
          </Box>
        )}

        {selectedChoice === "List of Figures" && displayOnEditorScreen && (
          <Box
            sx={{
              border: isDeleted
                ? "2px dashed Red !important"
                : props.element?.should_show && props.approvalsEnabled
                ? `5px dashed ${COLORS.NOMIA_GREEN}`
                : "2px solid silver.main",
              borderColor: isDeleted
                ? "Red !important"
                : props.element?.should_show && props.approvalsEnabled
                ? COLORS.NOMIA_GREEN
                : "",

              padding: "10px",
              borderRadius: "0px",
              paddingTop: "3px",

              paddingLeft: "10px",
              // fontFamily: font, // Set the font style
              fontFamily: desiredFont,
              lineHeight: lineSpacing, // Set the line spacing
              backgroundColor: "#F7F7F7", // Set the background color
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                zIndex: 99,
              }}
            >
              {!props.approvalsEnabled && !isUserFromShareLink() ? (
                <>
                  <Tooltip title="" placement="top">
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 20,
                        transition: "none", // Remove transition on button press
                        margin: 0,
                        padding: 0,
                        width: "fit-content",
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={handleDeleteClicked}
                    >
                      <DeleteIcon
                        style={{
                          fontSize: 22,
                          outline: "none",
                          color: "#7284A3",
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="List of Figure Settings" placement="top">
                    <IconButton
                      onClick={handleDialogOpen}
                      style={{
                        position: "absolute",
                        top: 2,
                        right: -5,
                        transition: "none", // Remove transition on button press
                        margin: 0,
                        padding: 0,
                        width: "fit-content",
                      }}
                    >
                      <SettingsIcon
                        style={{
                          fontSize: 22,
                          outline: "none",
                          color: "#7284A3",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              ) : isUserFromShareLink() ? (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 15,
                    transition: "none",
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99,
                  }}
                >
                  <CommentsIcon
                    totalComments={113}
                    onClick={() => {
                      alert();
                    }}
                  />
                </IconButton>
              ) : null}

              {isDeleted && props.approvalsEnabled && (
                <IconButton
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteOpen(true);
                    e.stopPropagation(); // Add this line to stop event propagation
                  }}
                  title="Undo Deletion"
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 3,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99999,
                  }}
                >
                  <UndoIcon
                    style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                  />
                </IconButton>
              )}
            </Box>

            <span
              style={{
                ...h1Style,
                // (((documentMasterFormat(
                //   documentMasterState,
                //   "HEADING",
                //   1
                // ).fontSize as number) *
                //   2) /
                //   96) *
                //   72 +
                // "px !important",
              }}
            >
              {Title}
            </span>

            {documentTemplate?.sections?.map((section, index) => (
              <div key={index}>
                {section.subsections?.map((subsection, subIndex) => (
                  <div key={subIndex}>
                    {subsection.elements?.map((element, elIndex) => {
                      if (
                        Array.isArray(subsection.elements) && // Check if elements is an array
                        subsection.elements.length > 0 &&
                        subsection.elements[0]?.content_type === "IMAGE" && // Added optional chaining
                        JSON.parse(subsection.elements[0].content_format)
                          .DisplyCaption === true
                      ) {
                        const caption = JSON.parse(
                          subsection.elements[0].content_format
                        ).caption;
                        countTableElement++;
                        return (
                          <p
                            key={elIndex}
                            style={{
                              paddingLeft: "25px",
                              lineHeight: isFollowMaster ? 1 : lineSpacing,
                              fontSize: isFollowMaster
                                ? (h2Formating.fontSize as number) * 0.85 + "pt"
                                : `${fontSize}pt`, // Set the font size
                              // color: color, // Set the text color
                              color: isFollowMaster
                                ? "#" + h2Formating.fontColor
                                : "#" + color, //color, // Set the text color
                              fontFamily: isFollowMaster
                                ? findMatchingFont(h2Formating.font)
                                : findMatchingFont(font),
                              fontStyle:
                                isFollowMaster &&
                                h2Formating.fontStyle.includes("italic")
                                  ? "italic"
                                  : isItalic
                                  ? "italic"
                                  : "normal",
                              textDecoration:
                                isFollowMaster &&
                                h2Formating.fontStyle.includes("underline")
                                  ? "underline"
                                  : isUnderline
                                  ? "underline"
                                  : "none",
                              fontWeight:
                                isFollowMaster &&
                                h1Formating.fontStyle.includes("bold")
                                  ? "bold"
                                  : isBold
                                  ? "bold"
                                  : "normal",

                              // marginLeft: isIndented ? "20px" : "0", // Conditionally apply indent for subsections
                            }}
                          >
                            {countTableElement +
                              Array(4).fill("\u00A0").join("") +
                              caption}
                            {/* {`${countTableElement}     ${caption}`} */}
                          </p>
                        );
                      }
                      return null;
                    })}
                  </div>
                ))}
              </div>
            ))}
          </Box>
        )}

        {/* The drag+drop element showing that you are going to drop something here. */}
        {/* {isOver ? <DropSkeleton /> : null} */}
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Edit Properties of{" "}
          {selectedChoice === "List of Tables" ? "Tables" : "Figures"}
        </DialogTitle>
        <Box sx={{ p: 3, py: 0 }}>
          <TextField
            autoFocus
            margin="dense"
            id="Title"
            label="Title"
            fullWidth
            variant="outlined"
            value={Title}
            placeholder="Provide Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />

          <Autocomplete
            value={fontFamilies.find((option) => option.value === font)}
            disablePortal
            options={fontFamilies}
            onChange={(event: any, newValue: any) => {
              const selectedValue = newValue ? newValue.value : null;
              setFont(selectedValue);
            }}
            renderInput={(params: any) => (
              <TextField {...params} label="Font" />
            )}
            disableClearable
            renderOption={(props, option) => (
              <li
                {...props}
                style={{ fontFamily: findMatchingFont(option.value) }}
              >
                {option.label}
              </li>
            )}
            sx={{ marginTop: 2 }}
          />

          {/* 
          <TextField
            sx={{ marginTop: 2 }}
            label="Font"
            value={font}
            onChange={(e) => {
              setFont(e.target.value);
            }}
            fullWidth
            select
            variant="outlined"
          >
            {fontFamiliess.map((font) => (
              <MenuItem key={font} value={font}>
                {font}
              </MenuItem>
            ))}
          </TextField> */}
          {/* <TextField
            sx={{ marginTop: 2 }}
            label="Color"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
            fullWidth
            select
            variant="outlined"
          >
            {colorOptions.map((color) => (
              <MenuItem key={color} value={color}>
                {color}
              </MenuItem>
            ))}
          </TextField> */}

          <Box sx={{ marginTop: "12px" }}>
            <ColorPicker
              label="Color"
              color={color}
              onChange={(newColor) => setColor(newColor)}
            />
          </Box>
          <TextField
            sx={{ marginTop: 2 }}
            label="Font Size"
            value={fontSize}
            onChange={(e) => {
              setFontsize(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
          <TextField
            sx={{ marginTop: 2 }}
            label="Line Spacing"
            value={lineSpacing}
            onChange={(e) => {
              setLineSpacing(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />

          <FormControl sx={{ marginTop: "15px" }} fullWidth variant="outlined">
            <InputLabel htmlFor="style-select">Style</InputLabel>
            <Select
              labelId="style-label"
              id="style-select"
              label="Style"
              variant="outlined"
              multiple
              value={selectedStyles}
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isStandard}
                      name="standard"
                      onChange={handleStyleChange}
                    />
                  }
                  label="Standard"
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBold}
                      name="bold"
                      onChange={handleStyleChange}
                    />
                  }
                  label="Bold"
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isItalic}
                      name="italic"
                      onChange={handleStyleChange}
                    />
                  }
                  label="Italic"
                />
              </MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ mt: 0 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Insert Page Break Above?
            </Typography>
            <Checkbox
              checked={isPageBreakAbove}
              onChange={(event) => setIsPageBreakAbove(event.target.checked)}
            />
          </Box>

          <Box sx={{ mt: 0 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Insert Page Break Below?
            </Typography>
            <Checkbox
              checked={isPageBreakBelow}
              onChange={(event) => setIsPageBreakBelow(event.target.checked)}
            />
          </Box>

          <Box sx={{ mt: 0 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Follow Document Template Master Settings?
            </Typography>
            <Checkbox
              checked={isFollowMaster}
              onChange={(event) => setIsFollowMaster(event.target.checked)}
            />
          </Box>
          {/* <Box sx={{ mt: 2 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Show List of{" "}
              {selectedChoice === "List of Tables" ? "Tables" : "Figures"} on
              Editor Screen?
            </Typography>
            <Checkbox
              checked={displayOnEditorScreen}
              onChange={(event: any) =>
                setDisplayOnEditorScreen(event.target.checked)
              }
            />
          </Box> */}
        </Box>
        <DialogActions>
          <Button variant="outlined" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={handleDialogSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {IsLibraryOpen && (
        <LibraryContentConfigFormSubSection
          closeDialogHandler={() => SetIsLibarayOpen(false)}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          viewPreferences={props.viewPreferences}
        />
      )}

      {isDeleteOpen === true ? (
        <EditorUndoForm
          isHeading={true}
          title={selectedChoice}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteOpen(false)}
        />
      ) : null}
    </>
  );
}
