import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { Alert, Box, DialogActions } from "@mui/material";
import * as XLSX from "xlsx";
import BulkIcon from "../../Icons/Document Bulk Generation/Variable Document.svg";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import WarningIcon from "@mui/icons-material/Warning";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSubsection
} from "../../../../../types/DocumentTemplate";
import useActions, {
  useAppDispatch,
  useAppSelector
} from "../../../../../app/hooks";
import {
  ExcelFileUpload,
  FileUploadProps
} from "../../../../shared/ExcelFileUpload";
import { setSelectedComponent } from "../../../editorSlice";
import { formatTableWithTheme } from "../HelperFuctions/TableUtils";

export default function ImportContentFromExcel(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection;
  parentCloseHandle: () => void;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [payload, setPayload] = React.useState<Array<Array<string>> | null>(
    null
  );
  const [errorMsg, setErrorMsg] = useState<string>("");

  const onClose = () => {
    props.parentCloseHandle();
  };

  const validateSanitizedData = (data: any) => {
    if (!Array.isArray(data)) {
      console.log("return from 1");
      return { isValid: false, maxRows: 0, maxColumns: 0 };
    }

    let maxRows = 0;
    let maxColumns = 0;

    for (const row of data) {
      if (!Array.isArray(row)) {
        console.log("return from 2");
        return { isValid: false, maxRows, maxColumns };
      }

      maxRows++;

      for (const cell of row) {
        if (typeof cell !== "string") {
          console.log("return from 3");
          return { isValid: false, maxRows, maxColumns };
        }
      }

      maxColumns = Math.max(maxColumns, row.length);
    }

    return { isValid: true, maxRows, maxColumns };
  };

  const processParsedData = (parsedData: any) => {
    const filteredData = parsedData.map((row: any) =>
      row.map((cell: string) => {
        if (
          cell == null ||
          cell == undefined ||
          cell == "" ||
          cell == " " ||
          cell == ""
        ) {
          return ""; // Or replace with your desired default value
        }
        return cell.toString();
      })
    );
    // Find the maximum row length
    const maxRowLength = Math.max(
      ...filteredData.map((row: any) => row.length)
    );
    // Make each row length the same
    const processedData = filteredData
      .map((row: any) => {
        // Pad the row with empty strings if its length is less than the maximum
        const paddedRow = row.concat(Array(maxRowLength - row.length).fill(""));
        return paddedRow;
      })
      .filter((row: any) => row.some((cell: any) => cell.trim() !== ""));

    const columnsToRemove: number[] = [];
    for (let colIndex = 0; colIndex < maxRowLength; colIndex++) {
      if (processedData.every((row: any) => row[colIndex].trim() === "")) {
        columnsToRemove.push(colIndex);
      }
    }

    const finalProcessedData = processedData.map((row: any) =>
      row.filter(
        (_: any, colIndex: number) => !columnsToRemove.includes(colIndex)
      )
    );

    return finalProcessedData;

    // return processedData;
  };

  const handleFileUpload = (uploadedFile: any) => {
    setErrorMsg("");
    setPayload(null);
    const file = uploadedFile;

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result;

        if (data) {
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
          const worksheet = workbook.Sheets[sheetName];

          // Parse data from the worksheet
          const parsedData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "" // Set the default value for empty cells to an empty string
          });
          const sanitizedData = processParsedData(parsedData);

          const isValid = validateSanitizedData(sanitizedData);
          if (!isValid.isValid) {
            setErrorMsg("Invalid Content Format");
            return;
          }
          // Set the sanitized data to your state or perform other actions

          // In Dynamic table we only take the first row and remove the white spaces columns
          if (props.element?.content_type === ContentType.dynamicTable) {
            const firstRow: string[][] = [
              sanitizedData[0].filter((value: any) => value !== "")
            ];
            console.log("sanitizedData", sanitizedData, "firstRow", firstRow);
            setPayload(firstRow);
            ///
          } else {
            setPayload(sanitizedData);
          }
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  const fileUploadProp: FileUploadProps = {
    accept: ".xlsx", // Accept only Excel files
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        const droppedFile = event.target.files[0];
        if (droppedFile && droppedFile.name.endsWith(".xlsx")) {
          setUploadedFile(droppedFile);
          setInvalidFileType(false);
          handleFileUpload(droppedFile);
        } else {
          setInvalidFileType(true);
          setUploadedFile(null);
          setErrorMsg("");
        }
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile && droppedFile.name.endsWith(".xlsx")) {
        setUploadedFile(droppedFile);
        setInvalidFileType(false);
        // handleFileUpload(droppedFile);
      } else {
        // Handle incorrect file type (not an Excel file)
        // setInvalidFileType(true);
        setUploadedFile(null);
        setErrorMsg("Please upload a valid Excel file (XLSX or XLS format).");
      }
    }
  };

  const InstructionsAccordion = (
    <>
      <Box boxShadow={3} sx={{ marginTop: 2 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "bold" }}>Instructions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <strong>Downloading the Excel Template</strong>
              <br />
              1. Click "Download" to get the Excel template.
              <br />
              2. Save the file.
              <br />
              <br />
              <strong>Filling the Excel Template</strong>
              <br />
              1. Duplicate the format for multiple documents.
              <br />
              2. Must provide unique document name.
              <br />
              3. Do not change the variable name.
              <br />
              4. Fill up the values in variable column.
              <br />
              <br />
              <strong>Uploading the Filled Excel File</strong>
              <br />
              1. Save the filled file.
              <br />
              2. Upload it to create Bulk templates.
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );

  const handleCreateTable = () => {
    if (payload != null) {
      const updatedDocumentElement = { ...props.element };
      const rows = payload.length;
      const columns = payload[0].length;
      const newTableArray: Array<string> = [];
      payload.forEach((_: any) => {
        newTableArray.push(_.join("!TC"));
      });
      const contentFormatObject = JSON.parse(props.element!.content_format);
      const formattedTableData = formatTableWithTheme(
        rows,
        columns,
        contentFormatObject.themeDetails
      );
      const stringFormat = JSON.stringify(formattedTableData);
      contentFormatObject.rows = rows;
      contentFormatObject.columns = columns;
      const newTableString = newTableArray.join("!TR");
      updatedDocumentElement.content_format =
        JSON.stringify(contentFormatObject);
      updatedDocumentElement.content = newTableString;
      updatedDocumentElement.formatting = stringFormat;

      actions.updateDocumentElement({
        section: props.parentSection as DocumentSection,
        subSection: props.parentSubsection!,
        documentElement: updatedDocumentElement as DocumentElement
      });

      // // setOriginalTable(updatedTable.newData);
      // setColumns(updatedTable.columnCount);
      // setRows(updatedTable.rowCount);
      dispatch(
        setSelectedComponent({
          selectedSection: props.parentSection as DocumentSection,
          selectedSubsection: props.parentSubsection!,
          selectedComponent: updatedDocumentElement as DocumentElement
        })
      );
    }
    setIsConfirmation(false);
    onClose();
  };

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle
          style={{
            textAlign: "left",
            display: "flex",
            alignItems: "left"
          }}
        >
          {/* <Typography
            sx={{ fontSize: "22px" }}
            id="Import Content From Excel__"
            variant="h5"
            component="h1"
          >
            Import content from excel
          </Typography> */}
          <Typography variant="h5" flexGrow={1}>
            Import content from excel
          </Typography>

          {/* <span style={{ flex: 1 }}>Bulk Document Creation</span> */}
        </DialogTitle>
        <DialogContent sx={{ width: "450px" }}>
          <ExcelFileUpload {...fileUploadProp} />
          {uploadedFile !== null && (
            <Typography
              sx={{
                p: 2,
                fontWeight: "bold",
                color: errorMsg == "" ? "green" : "red",
                fontSize: "14px"
              }}
            >
              {" "}
              File Name: {uploadedFile.name}
            </Typography>
          )}

          {invalidFileType ? (
            <Alert severity="error">
              Please upload a valid Excel file (XLSX or XLS format).
            </Alert>
          ) : null}
          <div
            style={{
              maxHeight: errorMsg ? "100px" : "0",
              overflow: "hidden",
              transition: "max-height 0.3s ease-in-out"
            }}
          >
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          </div>
          {/* <div style={{ textAlign: "center", margin: "20px 0" }}>
            {InstructionsAccordion}
          </div> */}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={errorMsg != "" || payload == null}
            style={{
              color: "#fff",
              boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
              textTransform: "uppercase",
              transition: "background-color 0.2s ease-in-out"
            }}
            onClick={() => {
              setIsConfirmation(true);
            }}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmation}
        onClose={() => setIsConfirmation(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{ textAlign: "center", fontWeight: "bold", color: "#f44336" }}
        >
          <WarningIcon
            style={{
              marginRight: "8px",
              fontSize: "24px",
              verticalAlign: "middle"
            }}
          />
          Warning: Import Content
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "24px"
            }}
          >
            {payload != null &&
              `Do you really want to create a table with ${payload[0].length} columns and ${payload.length} rows?.It will over write the previous content.`}

            {payload != null &&
              (payload[0].length > 8 || payload.length > 64) &&
              `Table Limit exceed this may cause issues.`}
          </p>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "16px" }}
        >
          <Button
            onClick={() => setIsConfirmation(false)}
            variant="outlined"
            style={{
              marginRight: "10px",
              color: "#f44336",
              borderColor: "#f44336",
              width: "80px"
            }}
          >
            Cancel
          </Button>

          <Button
            disabled={payload != null && payload[0].length > 12}
            onClick={handleCreateTable}
            style={{
              marginRight: "10px",

              width: "80px"
            }}
            variant="outlined"
          >
            create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
