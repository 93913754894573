import { MessageOutlined } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import React from "react";

type Props = {
  value: string;
  onComment: any;
  onChangeValue: any;
};

const AddCommentCard = (props: Props) => {
  return (
    <Box
      sx={{
        bgcolor: grey[200],
        paddingY: "10px",
        paddingX: "3px",
      }}
    >
      <Box
        sx={{
          bgcolor: grey[200],
        }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        <TextField
          id="outlined-multiline-static"
          label="Add comment here"
          value={props.value}
          onChange={(e) => {
            props.onChangeValue(e.target.value);
          }}
          fullWidth
          multiline
          InputLabelProps={{
            style: {
              fontSize: 12,
            },
          }}
          color={"secondary"}
          rows={3}
          InputProps={{
            style: { fontSize: 12, resize: "both" },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              //props.onComment();
            }
          }}
        />
        <IconButton disabled={!props.value} onClick={props.onComment}>
          <SendIcon />
        </IconButton>
      </Box>
      <Typography paddingX={"5px"} fontSize={"8px"} color={"gray"}>
        Commenting as <b>Haseeb Manzoor</b>
      </Typography>
    </Box>
  );
};

export default AddCommentCard;
