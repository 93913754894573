/**
 * Converts a column number to a spreadsheet column letter (e.g., 1 -> A, 5 -> E)
 *
 * @param colIndex - The index of the column (1-based index)
 * @returns The corresponding column letter as a string
 */
function convertColumnIndexToLetter(colIndex: number): string {
  let letter = "";
  while (colIndex > 0) {
    const mod = (colIndex - 1) % 26;
    letter = String.fromCharCode(65 + mod) + letter;
    colIndex = Math.floor((colIndex - mod) / 26);
  }
  return letter;
}

/**
 * Calculates the spreadsheet address given the row and column indices.
 *
 * @param rowIndex - The index of the row (1-based index)
 * @param colIndex - The index of the column (1-based index)
 * @returns The corresponding spreadsheet address (e.g., "A1", "B2", "AA10")
 */
export default function getSpreadsheetAddress(
  rowIndex: number,
  colIndex: number
): string {
  const columnLetter = convertColumnIndexToLetter(colIndex);
  return `${columnLetter}${rowIndex}`;
}

// Example Usage
const rowIndex = 1; // Example row index
const colIndex = 5; // Example column index
const address = getSpreadsheetAddress(rowIndex, colIndex);
console.log(address); // Output: "E1"
