import React, { Component, useState } from "react";
import TocIcon from "@mui/icons-material/Toc";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CheckBox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

import {
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearSelectedComponent,
  selectDocumentState,
  selectsHeadingNumber,
  selectSectionsNumber,
  setSelectedComponent,
} from "../editorSlice";
import Divider from "@mui/material/Divider";
import { ViewPreferences } from "../header/preferencesSlice";
import Typography from "@mui/material/Typography";
import { useDrop } from "react-dnd";
import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";

import { Approvals } from "./Approvals";
import { title } from "process";
import ReactMarkdown from "react-markdown";
import {
  applySeperators,
  documentMasterFormat,
  findMatchingFont,
} from "./HelperEditorFunctions";
import {
  selectDocumentMasterState,
  selectDocumentTemplateMaster,
} from "../header/documentMasterSlice";
import DeleteIcon from "@mui/icons-material/Delete";

// import { ReactComponent as SettingIcon } from "../drawers/right/icons/Settings.svg";

import SettingsIcon from "@mui/icons-material/Settings";
import { font_Families } from "../../../globals";
import { attachedSubsectionElement } from "./utils";
import EditorUndoForm from "./CustomEditorDecorators/EditorUndoForm";
import { shallowEqual } from "react-redux";
import { ColorPicker } from "./TableElement/ThemeFomattingSetting/CustomThemeConfig";
import { getSectionDocumentMaster } from "./CustomEditorDecorators/levelsHelperFunctions";
import { isUserFromShareLink } from "./TableElement/EditContent/helper";
import { CommentOutlined } from "@mui/icons-material";
import CommentsIcon from "src/features/comments/CommentsIcon";

/**
 * A table of contents.
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function TOCElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  parentSection__: DocumentSection | null;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  approvalsEnabled: boolean;
}) {
  const MAX_LENGTH = 32;
  const _TABLE_OF_CONTENTS = "Table of Contents";
  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const dispatch = useAppDispatch();
  const actions = useActions();
  const [dialogOpen, setDialogOpen] = useState(false);
  const documentMasterState = useAppSelector(selectDocumentMasterState);

  const documentTemplateState = useAppSelector(
    selectDocumentState,
    shallowEqual
  );
  const Label = styled("label")({
    color: "red",
  });

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const isDeleted =
    (props.parentSection.pending_deleted_at !== null &&
      props.parentSection.pending_deleted_at !== undefined) ||
    props.parentSubsection?.pending_deleted_at !== null;

  // TOC content_format:
  //'{"title":"Table-of-content","levelDepth":"3","font":"rmfamily","fontSize":"16" , "color": "black", "lineSpacing": "1.5", "indent" :"false" , "border": "F"}',
  // const startElement = React.useMemo(() => {
  //   return JSON.parse(props.element!.content_format);
  // }, []);

  const startElement = JSON.parse(props.element!.content_format);

  const [Title, setTitle] = React.useState<string>(startElement.title);
  const [titleToDisply, setTitleToDisplay] = React.useState<string>(
    startElement.title
  );
  const [levelDepth, setLevelDepth] = React.useState<string>(
    startElement.levelDepth
  );
  const [font, setFont] = React.useState<string>(
    startElement != null ? startElement.font : "rmfamily"
  );
  const [fontSize, setFontsize] = React.useState<string>(startElement.fontSize);
  const [color, setColor] = React.useState<string>(startElement.color);
  const [lineSpacing, setLineSpacing] = React.useState<string>(
    startElement.lineSpacing
  );

  const [border, setBorder] = React.useState<string>(startElement.border);
  const [isIndented, setIsIndented] = React.useState<boolean>(
    startElement.indent
  );

  const [isPageBreak, setIsPageBreak] = React.useState<boolean>(
    startElement.isPageBreak ? startElement.isPageBreak : false
  );
  const [isPageBreakAbove, setIsPageBreakAbove] = React.useState<boolean>(
    startElement.isPageBreakAbove ? startElement.isPageBreakAbove : false
  );

  const [isPageBreakBelow, setIsPageBreakBelow] = React.useState<boolean>(
    startElement.isPageBreakAbove ? startElement.isPageBreakAbove : false
  );

  const [isFollowMaster, setIsFollowMaster] = React.useState<boolean>(
    startElement.isFollowDtm ? startElement.isFollowDtm : false
  );

  const [isBold, setIsBold] = React.useState<boolean>(
    startElement.isBold ? startElement.isBold : false
  );
  const [isItalic, setIsItalic] = React.useState<boolean>(
    startElement.isItalic ? startElement.isItalic : false
  );
  const [isUnderline, setIsUnderline] = React.useState<boolean>(
    startElement.isUnderline ? startElement.isUnderline : false
  );

  const [isStandard, setIsStandard] = React.useState<boolean>(
    startElement.isStandard ? startElement.isStandard : false
  );

  const selectedSubHeadingNumber = useAppSelector(selectsHeadingNumber);
  const selectedSectioNumber = useAppSelector(selectSectionsNumber);

  const [selectedStyles, setSelectedStyles] = useState<string[]>(() => {
    const initialSelectedStyles: string[] = [];
    if (isStandard) {
      initialSelectedStyles.push("standard");
    }
    if (isBold) {
      initialSelectedStyles.push("bold");
    }
    if (isItalic) {
      initialSelectedStyles.push("italic");
    }
    if (isUnderline) {
      initialSelectedStyles.push("underline");
    }
    return initialSelectedStyles;
  });
  //  Implemention  for Style Check Boxes
  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case "bold":
        setIsBold(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "italic":
        setIsItalic(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "underline":
        setIsUnderline(checked);
        setIsStandard(false);
        setSelectedStyles((prevStyles) =>
          checked
            ? [
                ...prevStyles.slice(prevStyles.includes("standard") ? 1 : 0),
                name,
              ]
            : prevStyles.filter((style) => style !== name)
        );
        break;
      case "standard":
        setIsStandard(checked);
        setIsBold(false);
        setIsItalic(false);
        setIsUnderline(false);
        setSelectedStyles(checked ? ["standard"] : []);
        break;
      default:
        break;
    }
  };

  const [tocOnEditorScreen, setTocOnEditorScreen] =
    React.useState<boolean>(true);

  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(() => ({
        accept: "right-drawer-item",
        drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }));

  function addElementToSection(element: DocumentElement) {
    actions.createSubsectionWithElement({
      section: props.parentSection as DocumentSection,
      documentElement: element,
      subsectionAbove: props.parentSubsection!,
    });
  }
  function handleDialogOpen() {
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection,
        selectedComponent: props.element,
      })
    );
    setDialogOpen(true);
  }

  function handleDeleteClicked(e: any) {
    if (props.parentSection) {
      actions.deleteSection(props.parentSection as DocumentSection);
    }
    dispatch(clearSelectedComponent());
  }

  function resetValueToProps() {
    setTitle(startElement.title);
    setLevelDepth(startElement.levelDepth);
    setFont(startElement.font);
    setFontsize(startElement.fontSize);
    setLineSpacing(startElement.lineSpacing);
    setIsIndented(startElement.indent);
    setColor(startElement.color);
    setIsBold(startElement.isBold);
    setIsItalic(startElement.isItalic);
    setIsUnderline(startElement.isUnderLine);
    setIsFollowMaster(startElement.isFollowDtm);
  }
  function handleDialogClose() {
    setDialogOpen(false);
    resetValueToProps();
  }
  function handleDialogSave() {
    setTitleToDisplay(Title);
    const newParms = JSON.stringify({
      title: Title,
      levelDepth: levelDepth,
      font: font,
      fontSize: fontSize,
      color: color,
      lineSpacing: lineSpacing,
      indent: isIndented,
      border: border,
      isPageBreak: isPageBreak,
      isPageBreakBelow: isPageBreakBelow,
      isPageBreakAbove: isPageBreakAbove,
      isFollowDtm: isFollowMaster,
      isBold: isBold,
      isItalic: isItalic,
      isUnderline: isUnderline,
      isStandard: isStandard,
    });
    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection as DocumentSubsection,
      documentElement: {
        ...props.element,
        content_format: newParms,
      } as DocumentElement,
    });

    setDialogOpen(false);
  }

  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */
  function handleOnFocus(e: any): any {
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection,
        selectedComponent: props.element,
      })
    );
  }

  /**
   * Similarly track when focus is lost.
   * @param {*} e
   */
  function handleOnBlur(e: any) {
    dispatch(clearSelectedComponent());
  }

  const sectionheadingFormating = documentMasterFormat(
    documentMasterState,
    "HEADING",
    1
  );
  const fontFamilies = font_Families;

  const levelDepthList = ["1", "2", "3", "4", "5"];
  const colorOptions = [
    "black",
    "white",
    "red",
    "blue",
    "green",
    "yellow",
    "purple",
    "orange",
  ];

  const h1Formating = documentMasterFormat(documentMasterState, "HEADING", 1);

  const h1Style = {
    fontStyle: h1Formating.fontStyle.includes("italic") ? "italic" : "normal",
    textDecoration: h1Formating.fontStyle.includes("underline")
      ? "underline"
      : "none",
    fontWeight: h1Formating.fontStyle.includes("bold") ? "bold" : "normal",
    fontSize: (h1Formating.fontSize as number) + "pt",
    fontFamily: findMatchingFont(h1Formating.font),
    color: "#" + h1Formating.fontColor, //color, // Set the text color
    marginBottom: "16px !important", // Add some bottom margin
    paddingBottom: "16px !important",
    lineHeight: 2,
  };

  const subHeadingStyle = (level: number) => {
    const formatting =
      documentMasterFormat(documentMasterState, "HEADING", level) ??
      documentMasterFormat(documentMasterState, "HEADING", 1);

    return {
      // paddingLeft: "25px" ,
      paddingLeft: !isIndented
        ? "0"
        : level == 2
        ? "25px"
        : 8 * (level * 2) + "px",
      lineHeight: level == 1 ? 1 : lineSpacing,
      fontSize:
        isFollowMaster && level == 1
          ? (formatting.fontSize as number) * 0.85 + "pt"
          : isFollowMaster
          ? (formatting.fontSize as number) * 0.85 + "pt"
          : `${fontSize}pt`, // Set the font size
      // color: color, // Set the text color
      color: isFollowMaster ? "#" + formatting.fontColor : "#" + color, //color, // Set the text color
      fontFamily: isFollowMaster
        ? findMatchingFont(formatting.font)
        : findMatchingFont(font),
      fontStyle:
        isFollowMaster && formatting.fontStyle.includes("italic")
          ? "italic"
          : isItalic
          ? "italic"
          : "normal",
      textDecoration:
        isFollowMaster && formatting.fontStyle.includes("underline")
          ? "underline"
          : isUnderline
          ? "underline"
          : "none",
      fontWeight:
        isFollowMaster && formatting.fontStyle.includes("bold")
          ? "bold"
          : isBold
          ? "bold"
          : "normal",
    };
  };

  const hideNumbering = (sectionID: number) => {
    const sectionDocumentMaster = getSectionDocumentMaster(
      sectionID,
      documentMasterState.sectionsDocumentMaster,
      documentMasterState
    );

    return sectionDocumentMaster.numberingSetting == "NN";
  };

  let sectionCount = 0;

  return (
    <>
      <Box
        id={`section-${props.parentSection.id}`}
        style={{
          zIndex: "99",
        }}
        ref={drop}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        sx={{
          padding: 1,
          paddingTop: 1,
          // mx: 1,
        }}
      >
        <Approvals
          approvalsEnabled={props.approvalsEnabled}
          element={props.element}
          parentSubsection={props.parentSubsection}
          parentSection={props.parentSection__}
        />
        {props.viewPreferences.showIDs ? (
          <DebugID
            name="Element"
            id={props.element !== null ? props.element.id : -1}
            componentOrder={0}
          />
        ) : null}

        {/* <Box sx={{ padding:0 , margin:0 }}>
          <Box
            width={"100%"}
            style={{
              zIndex: "99",
            }}
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              style={{
                zIndex: "99",
              }}

              onClick={handleDialogOpen}
            >
              <SettingIcon style={{ fontSize: 10  , width:"4vh"}} />
            </Button>
          </Box>
        </Box> */}

        {tocOnEditorScreen && (
          <Box
            sx={{
              border: isDeleted
                ? "2px dashed Red !important"
                : "2px solid silver.main",
              borderColor: isDeleted ? "Red !important" : "",
              borderRadius: "0px",
              padding: "10px",
              paddingTop: "3px",
              paddingLeft: "7px",
              // fontFamily: font, // Set the font style
              fontFamily: findMatchingFont(font),
              lineHeight: lineSpacing, // Set the line spacing
              backgroundColor: "#F7F7F7", // Set the background color
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                zIndex: 99,
              }}
            >
              {!props.approvalsEnabled && !isUserFromShareLink() ? (
                <>
                  <Tooltip title="" placement="top">
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 20,
                        transition: "none", // Remove transition on button press
                        margin: 0,
                        padding: 0,
                        width: "fit-content",
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={handleDeleteClicked}
                    >
                      <DeleteIcon
                        style={{
                          fontSize: 22,
                          outline: "none",
                          color: "#7284A3",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Table of Contents Settings" placement="top">
                    <IconButton
                      onClick={handleDialogOpen}
                      style={{
                        position: "absolute",
                        top: 2,
                        right: -5,
                        transition: "none", // Remove transition on button press
                        margin: 0,
                        padding: 0,
                        width: "fit-content",
                      }}
                    >
                      <SettingsIcon
                        style={{
                          fontSize: 22,
                          outline: "none",
                          color: "#7284A3",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              ) : isUserFromShareLink() ? (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 7,
                    transition: "none",
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99,
                  }}
                >
                  <CommentsIcon
                    totalComments={113}
                    onClick={() => {
                      alert();
                    }}
                  />
                </IconButton>
              ) : null}

              {isDeleted && props.approvalsEnabled && !isUserFromShareLink() ? (
                <IconButton
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteOpen(true);
                    e.stopPropagation(); // Add this line to stop event propagation
                  }}
                  title="Undo Deletion"
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 3,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99999,
                  }}
                >
                  <UndoIcon
                    style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                  />
                </IconButton>
              ) : isUserFromShareLink() ? (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 7,
                    transition: "none",
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99,
                  }}
                >
                  <CommentsIcon
                    totalComments={113}
                    onClick={() => {
                      alert();
                    }}
                  />
                </IconButton>
              ) : null}
            </Box>
            <span
              style={{
                ...h1Style,
                paddingBottom: "10px",
              }}
            >
              {titleToDisply}
            </span>
            {documentTemplateState.documentTemplate?.sections?.map(
              (section, index) => {
                let subCount = 1;
                if (
                  section.heading !== "Table of Contents Section" &&
                  section.heading !== "List of Figures" &&
                  section.heading !== "List of Tables" &&
                  section.heading !== "Title Page"
                ) {
                  sectionCount++;
                  return (
                    !hideNumbering(section.id) && (
                      <div style={{ paddingBottom: "10px" }} key={index}>
                        <div style={{ display: "flex", paddingBottom: "5px" }}>
                          {!hideNumbering(section.id) && (
                            <div style={{ flex: "none" }}>
                              <span
                                style={{
                                  ...subHeadingStyle(1),
                                  paddingRight: "8px",
                                  paddingLeft: 0,
                                }}
                              >
                                {!hideNumbering(section.id) &&
                                  applySeperators(
                                    h1Formating,
                                    selectedSectioNumber.find(
                                      (s) => s.id === section.id
                                    )?.count ?? 1
                                  )}
                              </span>
                            </div>
                          )}
                          <div style={{ flex: "auto" }}>
                            <span
                              style={{
                                ...subHeadingStyle(1),
                                paddingLeft: "0px",
                              }}
                            >
                              {(section.pending_heading != null
                                ? section.pending_heading
                                : section.heading
                              ).replace(/(\+\+|##|_|\*)/g, "")}
                            </span>
                          </div>
                        </div>
                        {/* 
                      <span
                        style={{
                          // color: color,
                          // // color:"#97ff5d",
                          // fontSize: fontSize + "px",
                          // marginBottom: "10px", // Add some bottom margin
                          // fontWeight: "bold", // Add bold font weight
                          ...subHeadingStyle(1),
                          paddingLeft: "0", // Conditionally apply indent for subsections
                        }}
                      >
                 
                        {`${applySeperators(h1Formating,sectionCount)} ${(section.pending_heading!=null? section.pending_heading : section.heading).replace(
                          /(\+\+|##|_|\*)/g,
                          ""
                        )}`}
                      </span> */}
                        {section.subsections?.map((subsection, subIndex) => {
                          if (
                            subsection?.elements![0].content_type == "HEADING"
                          ) {
                            const subNumber = hideNumbering(section.id)
                              ? ""
                              : selectedSubHeadingNumber.find(
                                  (s) => s.subSectionId == subsection?.id
                                )?.number;

                            // `${sectionCount}.${subCount}`;
                            subCount++;
                            return (
                              parseInt(levelDepth) >=
                                subsection?.elements![0].heading_level && (
                                <p
                                  style={{
                                    ...subHeadingStyle(
                                      subsection?.elements![0].heading_level
                                    ),
                                    margin: 0,
                                    marginTop: "8px",
                                  }}
                                >
                                  {`${subNumber} ${truncate(
                                    attachedSubsectionElement(
                                      subsection
                                    ).content.replace(
                                      /(\+\+|_|[*]{1,2}|##)/g,
                                      ""
                                    ),
                                    300
                                  )}`}
                                </p>
                              )
                            );
                          }
                        })}
                      </div>
                    )
                  );
                }
                // return null;
              }
            )}
          </Box>
        )}
        {/* The drag+drop element showing that you are going to drop something here. */}
        {/* {isOver ? <DropSkeleton /> : null} */}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          {_TABLE_OF_CONTENTS}
        </DialogTitle>
        <Box sx={{ px: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            id="Title"
            label="Title"
            fullWidth
            variant="outlined"
            value={Title}
            placeholder="Provide Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{ marginTop: 2, paddingBottom: 0, marginBottom: 0, gap: 3 }}
              >
                <TextField
                  label="LevelDepth"
                  value={levelDepth}
                  onChange={(e) => {
                    setLevelDepth(e.target.value);
                  }}
                  fullWidth
                  select
                  variant="outlined"
                >
                  {levelDepthList.map((font) => (
                    <MenuItem key={font} value={font}>
                      {font}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ marginTop: 0.5, gap: 3 }}>
                <Autocomplete
                  value={fontFamilies.find((option) => option.value === font)}
                  disablePortal
                  options={fontFamilies}
                  onChange={(event: any, newValue: any) => {
                    const selectedValue = newValue ? newValue.value : null;
                    setFont(selectedValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} label="Font" variant="outlined" />
                  )}
                  disableClearable
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{ fontFamily: findMatchingFont(option.value) }}
                    >
                      {option.label}
                    </li>
                  )}
                  sx={{ marginTop: 2 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ marginTop: 1 }}>
                <ColorPicker
                  label="Color"
                  color={color}
                  onChange={(newColor) => setColor(newColor)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                id="FontSize"
                label="Font Size"
                fullWidth
                variant="outlined"
                value={fontSize}
                placeholder="Provide Font Size"
                onChange={(e) => {
                  setFontsize(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                id="LineSpacing"
                label="Line Spacing"
                fullWidth
                variant="outlined"
                value={lineSpacing}
                placeholder="Provide Line Spacing in mm"
                onChange={(e) => {
                  setLineSpacing(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ marginTop: "7px" }}>
              <Box>
                <FormControl
                  // sx={{ marginTop: "10px"  }}
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel htmlFor="style-select">Style</InputLabel>
                  <Select
                    // sx={{paddingBottom:"2px"}}
                    labelId="style-label"
                    id="style-select"
                    label="Style"
                    variant="outlined"
                    multiple
                    value={selectedStyles}
                    renderValue={(selected) =>
                      (selected as string[]).join(", ")
                    }
                  >
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isStandard}
                            name="standard"
                            onChange={handleStyleChange}
                          />
                        }
                        label="Standard"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isBold}
                            name="bold"
                            onChange={handleStyleChange}
                          />
                        }
                        label="Bold"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isItalic}
                            name="italic"
                            onChange={handleStyleChange}
                          />
                        }
                        label="Italic"
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 0 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Do you want to indent?
            </Typography>
            <Checkbox
              checked={isIndented}
              onChange={(event) => setIsIndented(event.target.checked)}
            />
          </Box>
          <Box sx={{ mt: 0 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Do you want Insert Page Break Above?
            </Typography>
            <Checkbox
              checked={isPageBreakAbove}
              onChange={(event) => setIsPageBreakAbove(event.target.checked)}
            />
          </Box>
          <Box sx={{ mt: 0 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Do you want Insert Page Break Below?
            </Typography>
            <Checkbox
              checked={isPageBreakBelow}
              onChange={(event) => setIsPageBreakBelow(event.target.checked)}
            />
          </Box>

          <Box sx={{ mt: 0 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Follow Document Template Master Settings?
            </Typography>
            <Checkbox
              checked={isFollowMaster}
              onChange={(event) => setIsFollowMaster(event.target.checked)}
            />
          </Box>

          {/* <Box sx={{ mt: 0.5 }}>
            <Typography sx={{ display: "inline", mr: 1 }}>
              Show Table of Content on Editor Screen?
            </Typography>
            <Checkbox
              checked={tocOnEditorScreen}
              onChange={(event: any) =>
                setTocOnEditorScreen(event.target.checked)
              }
            />
          </Box> */}
        </Box>
        <DialogActions sx={{ py: 2 }}>
          <Button onClick={() => handleDialogClose()} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleDialogSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {isDeleteOpen === true ? (
        <EditorUndoForm
          isHeading={true}
          title="Table of Contents"
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteOpen(false)}
        />
      ) : null}
    </>
  );
}
