export const calculateTrailRemainingDays = (
  expiryDateStr: string | undefined | null
) => {
  if (expiryDateStr) {
    // Parse the expiry_date to a Date object
    const expiryDate = new Date(expiryDateStr);

    // Get the current date
    const currentDate = new Date();

    // Set the time portion of the dates to 00:00:00 to ignore hours
    expiryDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Calculate the difference in time (in milliseconds)
    const timeDifference = expiryDate.getTime() - currentDate.getTime();

    // Convert time difference from milliseconds to days
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return {
      remainingDays: remainingDays,
      percentage: (remainingDays / 40) * 80
    };
  } else {
    return {
      remainingDays: 0,
      percentage: 100
    };
  }
};
