import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";

const CustomWidth = (props: {
  onClose: any;
  handleSave: any;
  column: number;
  width?: any;
}) => {
  const [width, setWidth] = useState<number>(
    props.width ? parseInt(props.width) : 0
  );

  const column = React.useMemo(() => {
    if (props.column != 1000 && props.column != undefined) {
      return props.column;
    }
  }, []);

  const handleInputBlur = (event: any) => {
    // Prevent the onBlur event from propagating to the parent component
    event.preventDefault();
    event.stopPropagation();
  };

  const handleSave = () => {
    props.handleSave(width, column);
    ///
  };

  return (
    <Box
      zIndex={999999999999999}
      style={{
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
        borderRadius: "1px",
        padding: "10px",
        background: "#fff",
        position: "absolute",
      }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <TextField
        sx={{ zIndex: 10000, width: "150px" }}
        type="number"
        value={width}
        label={"Column Width"}
        onMouseEnter={handleInputBlur}
        onFocus={handleInputBlur}
        onBlur={handleInputBlur}
        onChange={(event) => {
          event.preventDefault();
          const inputValue = parseFloat(event.target.value);
          if (!isNaN(inputValue) && inputValue >= 0) {
            setWidth(Number(event.target.value)); // Convert the value to a number
            event.stopPropagation();
          }
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <Button
          sx={{ marginRight: "2px" }}
          fullWidth
          variant="outlined"
          onClick={props.onClose}
        >
          Close
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSave}
          onMouseDown={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          Save
        </Button>
      </div>
    </Box>
  );
};

export default CustomWidth;
