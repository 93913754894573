import { MarkChatUnreadOutlined } from "@mui/icons-material";
import { Box, Container, Drawer, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CommentsCard from "./CommentsCard";
import AddCommentCard from "./AddCommentCard";

type Props = {
  data?: any;
};

const drawerWidth = "19vw";

const CommentsContainer = (props: Props) => {
  const [comment, setcomment] = useState("");

  const [arr, setarr] = useState([
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
  ]);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [arr]);

  console.log(arr);

  const Warning = () => (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      padding={"20px"}
      justifyContent={"center"}
      justifyItems={"center"}
      justifySelf={"center"}
    >
      <MarkChatUnreadOutlined
        style={{
          textAlign: "center",
          color: "gray",
          display: "flex",
          alignSelf: "center",
        }}
      />
      <Typography fontSize={"small"} textAlign={"center"} color={"gray"}>
        Please select any comment to display
      </Typography>
    </Box>
  );
  const { data } = props;
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
          mx: 0,
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      anchor="right"
      // ref={messagesEndRef}
    >
      {data ? (
        <Warning />
      ) : (
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            overflow: "hidden",
          }}
        >
          <Box
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              overflow: "auto",
            }}
          >
            {arr.map((comments: any, index: number) => (
              <CommentsCard item={comments} key={index} />
            ))}
            <div ref={messagesEndRef} />
          </Box>
          <AddCommentCard
            value={comment}
            onChangeValue={setcomment}
            onComment={() => {
              setarr((prev) => [...prev, comment]);
              setcomment("");
            }}
          />
        </Box>
      )}
    </Drawer>
  );
};

export default CommentsContainer;
