import { CommentOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  onClick: any;
  disabled?: boolean | null;
  totalComments: number;
};

const CommentsIcon = (props: Props) => {
  const { onClick, totalComments } = props;
  return (
    <Box onClick={onClick} display={"flex"}>
      <CommentOutlined
        style={{
          fontSize: 22,
          outline: "none",
          color: "#7284A3",
        }}
      />
      <Typography variant="body1">{totalComments}</Typography>
    </Box>
  );
};

export default CommentsIcon;
