import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFillTwoTone";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ColorPicker from "src/features/shared/UtilitesComponents/ColorPicker";
import useActions, { useAppSelector } from "../../../../../app/hooks";
import { selectVariables } from "src/features/editor/editorSlice";
import { fontSizes, functionsList } from "./helper";
import DropdownSelector from "src/features/shared/UtilitesComponents/DropdownSelector";
import { Check, Key } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import CustomWidth from "../ContextMenu/CustomWidth";
import { getCellStyle } from "../HelperFuctions/TableUtils";

type Alignment = "left" | "center" | "right";
type Color = string;

export interface CellStyle {
  alignment: string; // Assuming alignment can be one of these three values
  fontSize: string; // Font size as a string
  backgroundColor: string; // Color as a hex string without the '#'
  color: string; // Color as a hex string without the '#',
  textDecoration: any;
  fontWeight: any;
  fontStyle: any;
}

interface ToolbarProps {
  style: CellStyle;
  onAlignmentChange: (alignment: Alignment) => void;
  onTextColorChange: (color: Color) => void;
  onBackgroundColorChange: (color: Color) => void;
  onFontSizeChange: (font: string) => void;
  onFormattingChange: (style: {
    textDecoration: string;
    fontWeight: string;
    fontStyle: string;
  }) => void;
  appendContent: (text: string) => void;
  onOpenSettings: any;
  selectedCell: any;
  formatting: any;
  handleUpdateFormatting: any;
  OnOpenColWidth: any;
  setCustomTableDetails: any;
  customTableDetails: any;
  setColType: any;
  colType: any;
  rowType: any;
  setRowType: any;
  rowSize: any;
  setRowSize: any;
  setIsApplied: any;
  setisContentChanges: any;
}

const Toolbar: React.FC<ToolbarProps> = ({
  style,
  onAlignmentChange,
  onTextColorChange,
  onBackgroundColorChange,
  onFontSizeChange,
  onFormattingChange,
  appendContent,
  onOpenSettings,
  selectedCell,
  formatting,
  handleUpdateFormatting,
  OnOpenColWidth,
  setCustomTableDetails,
  customTableDetails,
  setColType,
  colType,
  rowType,
  setRowType,
  rowSize,
  setRowSize,
  setIsApplied,
  setisContentChanges,
}) => {
  const selectedDocumentVariables = useAppSelector(selectVariables);
  const [alignment, setAlignment] = useState<string>(style.alignment);
  const [bgPickerOpen, setBgPickerOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [textColor, setTextColor] = useState<Color>(style.color);
  const [backgroundColor, setBackgroundColor] = useState<Color>(
    style.backgroundColor
  );
  const [fontWeight, setFontWeight] = useState<string>(style.fontWeight);
  const [fontStyle, setFontStyle] = useState<string>(style.fontStyle);
  const [textDecoration, setTextDecoration] = useState<string>(
    style.textDecoration
  );

  const [fontSize, setFontSize] = useState<string>(style.fontSize);

  const [openWidth, setopenWidth] = useState(false);

  const getvariableList = () => {
    if (!selectedDocumentVariables) return [];
    return selectedDocumentVariables?.map((variable) => {
      return {
        key: "@" + variable.variable_name,
        value: "@" + variable.variable_name,
        url: "Not used",
      };
    });
  };

  const variableList = getvariableList();
  const formattedFunctionsList = functionsList.map((func) => ({
    key: func.name,
    value: func.name,
  }));

  // console.log("customTableDetails", customTableDetails);

  const handleFormattingChange = (newFormatting: Partial<CellStyle>) => {
    const updatedFormatting = {
      textDecoration: newFormatting.textDecoration || textDecoration,
      fontWeight: newFormatting.fontWeight || fontWeight,
      fontStyle: newFormatting.fontStyle || fontStyle,
    };
    setFontWeight(updatedFormatting.fontWeight);
    setFontStyle(updatedFormatting.fontStyle);
    setTextDecoration(updatedFormatting.textDecoration);
    onFormattingChange(updatedFormatting);
  };

  useEffect(() => {
    setAlignment(style.alignment);
    setTextColor(style.color);
    setBackgroundColor(style.backgroundColor);
    setFontSize(style.fontSize);
    setFontWeight(style.fontWeight);
    setFontStyle(style.fontStyle);
    setTextDecoration(style.textDecoration);
  }, [style]);

  //Colum Width Settings
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setisContentChanges(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //row height actions
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorE2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
    setisContentChanges(false);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#a8a7a7",
      }}
    >
      {/* Bold, Italic, Underline Buttons */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Bold">
          <IconButton
            onClick={() => {
              const newFontWeight = fontWeight === "bold" ? "normal" : "bold";
              handleFormattingChange({ fontWeight: newFontWeight });
            }}
            color={fontWeight === "bold" ? "primary" : "default"}
          >
            <FormatBoldIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Italic">
          <IconButton
            onClick={() => {
              const newFontStyle = fontStyle === "italic" ? "normal" : "italic";
              handleFormattingChange({ fontStyle: newFontStyle });
            }}
            color={fontStyle === "italic" ? "primary" : "default"}
          >
            <FormatItalicIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Underline">
          <IconButton
            onClick={() => {
              const newTextDecoration =
                textDecoration === "underline" ? "none" : "underline";
              handleFormattingChange({ textDecoration: newTextDecoration });
            }}
            color={textDecoration === "underline" ? "primary" : "default"}
          >
            <FormatUnderlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Left Align">
          <IconButton
            onClick={() => {
              setAlignment("left");
              onAlignmentChange("left");
            }}
            color={alignment === "left" ? "primary" : "default"}
          >
            <FormatAlignLeftIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Center Align">
          <IconButton
            onClick={() => {
              setAlignment("center");
              onAlignmentChange("center");
            }}
            color={alignment === "center" ? "primary" : "default"}
          >
            <FormatAlignCenterIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Right Align">
          <IconButton
            onClick={() => {
              setAlignment("right");
              onAlignmentChange("right");
            }}
            color={alignment === "right" ? "primary" : "default"}
          >
            <FormatAlignRightIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title="Text Color">
          <IconButton>
            <FormatColorTextIcon
              style={{ color: textColor }}
              onClick={() => {
                setColorPickerOpen(true);
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
      <ColorPicker
        showColorPicker={colorPickerOpen}
        color={textColor}
        handleColorPickerSave={(color: string) => {
          onTextColorChange(color);
          setTextColor(color);
          setColorPickerOpen(false);
        }}
        handleColorPickerClose={() => setColorPickerOpen(false)}
      />

      {/* Background Color Picker */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title="Background Color">
          <IconButton>
            <FormatColorFillIcon
              style={{ color: backgroundColor }}
              onClick={() => {
                setBgPickerOpen(true);
              }}
            />
          </IconButton>
        </Tooltip>
        <ColorPicker
          showColorPicker={bgPickerOpen}
          color={backgroundColor}
          handleColorPickerSave={(color: string) => {
            setBackgroundColor(color);
            onBackgroundColorChange(color);
            setBgPickerOpen(false);
          }}
          handleColorPickerClose={() => setBgPickerOpen(false)}
        />
      </div>

      <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
        <Select
          value={fontSize}
          onChange={(event) => {
            const size = event.target.value as string;
            setFontSize(size);
            onFontSizeChange(size);
          }}
          sx={{
            backgroundColor: "transparent", // Set background color to white
            borderRadius: 0, // Remove border radius
            "& .MuiFilledInput-root": {
              backgroundColor: "transparent",
            },
            "&:before, &:after": {
              borderBottom: "none", // Remove the underline
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "none", // Remove underline on hover
            },
            boxShadow: "none", // Remove box-shadow
            border: "none", // Remove border
          }}
        >
          {fontSizes.map((size) => (
            <MenuItem key={size} value={size.toString()}>
              {size}pt
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DropdownSelector
        items={variableList}
        onItemSelect={appendContent}
        buttonLabel="@Variables"
      />

      <DropdownSelector
        items={formattedFunctionsList}
        onItemSelect={appendContent}
        buttonLabel="Functions"
      />

      {/* <Button onClick={() => setopenWidth(true)}>
        <Typography color={grey[900]} fontWeight={"bold"} fontSize={14}>
          COLUMN WIDTH
        </Typography>
      </Button> */}
      {openWidth && (
        <Box
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <CustomWidth
            width={
              getCellStyle(
                selectedCell.row - 1,
                selectedCell.col - 1,
                formatting
              ).columnWidth
            }
            onClose={() => {
              setopenWidth(false);
            }}
            handleSave={(width: any, _column: any) => {
              handleUpdateFormatting("Column Width", String(width), _column);
              setopenWidth(false);
            }}
            column={selectedCell.col - 1}
          />
        </Box>
      )}

      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography color={grey[900]} fontWeight={"bold"} fontSize={14}>
          COLUMN WIDTH
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setColType("automatic");
            setopenWidth(false);
            setisContentChanges(true);
            handleClose();
          }}
          sx={{
            backgroundColor: colType === "automatic" ? grey[300] : "#FFFFFF",
          }}
        >
          Automatic
        </MenuItem>
        <MenuItem
          onClick={() => {
            setColType("equal");
            setopenWidth(false);
            setisContentChanges(true);
            handleClose();
          }}
          sx={{
            backgroundColor: colType === "equal" ? grey[300] : "#FFFFFF",
          }}
        >
          Equal
        </MenuItem>
        <MenuItem
          onClick={() => {
            setColType("manual");
            setopenWidth(false);
            setisContentChanges(true);
            handleClose();
          }}
          sx={{
            backgroundColor: colType === "manual" ? grey[300] : "#FFFFFF",
          }}
        >
          Manual
        </MenuItem>
      </Menu>

      <Button
        id="basic-button"
        aria-controls={open2 ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? "true" : undefined}
        onClick={handleClick2}
      >
        <Typography color={grey[900]} fontWeight={"bold"} fontSize={14}>
          ROW HEIGHT
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setRowType("automatic");
            setisContentChanges(true);
            setIsApplied(false);
          }}
          sx={{
            backgroundColor: rowType === "automatic" ? grey[300] : "#FFFFFF",
          }}
        >
          Automatic
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowType("equal");
            setIsApplied(false);
          }}
          sx={{
            backgroundColor: rowType === "equal" ? grey[300] : "#FFFFFF",
          }}
        >
          Equal
        </MenuItem>

        {rowType === "equal" && (
          <MenuItem
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ padding: 0, margin: 0 }}
            >
              <TextField
                sx={{ padding: 0, margin: 0 }}
                fullWidth
                margin="normal"
                type="number"
                value={rowSize}
                label="Height Value"
                // title="Enter 0 to fit content"
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  setIsApplied(false);
                  if (!isNaN(inputValue) && inputValue >= 0) {
                    setRowSize(e.target.value);
                  }
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">Pt</InputAdornment>
                    </>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <Button
              variant="outlined"
              sx={{ marginTop: 1 }}
              onClick={() => {
                setIsApplied(true);
                setisContentChanges(true);
                handleClose2();
              }}
            >
              Apply
            </Button>
          </MenuItem>
        )}
      </Menu>

      <Button onClick={onOpenSettings}>
        <Typography color={grey[900]} fontWeight={"bold"} fontSize={14}>
          SETTINGS
        </Typography>
      </Button>
    </div>
  );
};

export default Toolbar;
